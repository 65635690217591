<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="fixed inset-0 flex items-center justify-center px-2">
      <DialogPanel class="flex size-full max-h-[500px] min-h-96 max-w-lg flex-col overflow-y-auto rounded bg-gray-50 p-2 text-gray-700 md:p-4">
        <button
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="size-4 fill-gray-700 md:size-5" />
        </button>
        <DialogTitle class="mb-2 text-center font-serif text-xl text-gray-700 md:mb-3 md:text-2xl">
          Crear una reseña
        </DialogTitle>
        <DialogDescription
          class="mb-5 list-disc px-3 text-left text-sm font-light text-gray-700 md:px-4 md:text-base"
          as="ul"
        >
          <li>Solo puedes agregar una reseña de un pedido entregado.</li>
          <li>
            Deja todos los detalles necesarios en el comentario.
          </li>
          <li>
            Adicionalmente, puedes dejar un comentario privado para la marca.
          </li>
        </DialogDescription>
        <form
          class="flex h-full flex-col"
          @submit="onSubmit"
        >
          <div
            v-if="subOrdersIsLoading"
            class="mb-2 h-7 w-full rounded-md md:h-8"
          >
            <div class="h-full animate-shimmer rounded-md bg-gradient-to-r from-gray-200 via-white to-gray-200 bg-[length:200%_100%]" />
          </div>
          <div
            v-else
            class="mb-2 flex flex-col"
          >
            <lokal-generic-combobox
              v-if="subOrders?.length && subOrders.length > 0"
              v-model="selectedSubOrder"
              :options="subOrders"
              :get-option-key="(subOrder: SubOrder) => subOrder.id"
              :get-option-label="(subOrder: SubOrder) => subOrder?.orderId ? getSubOrderLabel(subOrder) : ''"
              compare-by="id"
              options-label="Pedidos"
              :multiple="false"
              class="font-light"
            />
            <Field
              v-model="selectedSubOrderId"
              name="subOrderId"
              label="Pedido"
              class="w-full"
              hidden
              :rules="{ required: true }"
            />
            <ErrorMessage
              name="subOrderId"
              class="mt-1 text-xs font-light text-red-500 md:text-sm"
            />
          </div>
          <template v-if="selectedSubOrderId">
            <button
              class="mb-1 flex items-center justify-between"
              type="button"
              @click="showAdditionalEvaluations = !showAdditionalEvaluations"
            >
              <p class="text-sm font-light text-gray-700 hover:underline md:text-base">
                Calificación de
                <span class="mr-1 font-semibold">{{ selectedSubOrder.makerName }}</span>
                <span
                  v-if="showAdditionalEvaluations"
                  class="font-semibold"
                >
                  ({{ computedRating }})
                </span>
              </p>
              <div class="flex items-center space-x-1 text-xs font-light md:text-sm">
                <span>Ver detalles</span>
                <ChevronDownIcon
                  class="size-4 fill-gray-700 transition-transform duration-300"
                  :class="{ 'rotate-180': showAdditionalEvaluations }"
                />
              </div>
            </button>
            <star-review
              v-model="form.rating"
              class="mb-2"
              :disabled="showAdditionalEvaluations"
            />
            <div v-if="showAdditionalEvaluations">
              <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
                Tiempos de entrega
              </p>
              <star-review
                v-model="form.deliveryTimelinessRating"
                class="mb-2"
              />
              <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
                Evaluación del envío
              </p>
              <star-review
                v-model="form.shippingRating"
                class="mb-2"
              />
              <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
                Calidad de los productos
              </p>
              <star-review
                v-model="form.qualityRating"
                class="mb-2"
              />
              <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
                Calidad del embalaje
              </p>
              <star-review
                v-model="form.packagingRating"
                class="mb-2"
              />
              <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
                Calificación general
              </p>
              <star-review
                v-model="form.overallRating"
                class="mb-2"
              />
            </div>
            <p class="mb-1 text-sm font-light text-gray-700 md:text-base">
              Comentario
            </p>
            <div class="mb-2 flex flex-col">
              <Field
                v-model="form.comment"
                name="comment"
                label="Comentario"
                class="w-full rounded-md p-1 text-xs font-light focus:border focus:border-lk-green focus:outline-none md:p-1.5 md:text-sm"
                as="textarea"
                :rules="{ required: true }"
              />
              <ErrorMessage
                name="comment"
                class="text-xs font-light text-red-500 md:text-sm"
              />
            </div>
            <div class="mb-4 flex flex-col">
              <lokal-tooltip
                class="font-light text-gray-700"
                :main-label="`Comentario privado`"
                information="Puedes usar este campo para dejar sugerencias privadas a la marca. Solo la marca lo verá."
                button-classes="flex items-center justify-center space-x-1"
                information-classes="w-40 md:w-52"
                information-position="left"
                arrow-color-classes="border-lk-light-purple"
                information-color-classes="bg-lk-light-purple"
                position-options="left"
              />
              <Field
                v-model="form.privateComment"
                name="privateComment"
                label="Comentario privado"
                class="w-full rounded-md p-1 text-xs font-light focus:border focus:border-lk-green focus:outline-none md:p-1.5 md:text-sm"
                as="textarea"
                :rules="{ required: true }"
              />
              <ErrorMessage
                name="privateComment"
                class="text-xs font-light text-red-500 md:text-sm"
              />
            </div>
          </template>
          <lokal-loader-button
            v-model="isCreateMakerReviewLoading"
            type="submit"
            class="mt-auto flex w-full items-center justify-center self-center rounded-md border border-lk-green p-1.5 hover:bg-lk-green hover:text-white md:p-2"
            :class="{
              'bg-lk-green text-white': isCreateMakerReviewLoading,
              'bg-white text-lk-green': !isCreateMakerReviewLoading,
            }"
            loading-text="Creando..."
          >
            Crear
          </lokal-loader-button>
        </form>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useMutation } from 'vue-query';
import { Field, ErrorMessage, useForm } from 'vee-validate';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { round } from 'lodash';
import makerReviewsApi from 'api/maker-reviews';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import starReview from './shared/star-review.vue';
import lokalLoaderButton from './shared/lokal-loader-button.vue';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useShopSubOrdersQuery from './queries/shopSubOrdersQuery';

const MAX_RATING = 5;

interface LokalMessageDialogProps {
  modelValue: boolean
  shopId: number
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'reload:makerReviews'): void}>();

const form = ref({
  rating: 0,
  comment: '',
  privateComment: '',
  deliveryTimelinessRating: 0,
  shippingRating: 0,
  qualityRating: 0,
  packagingRating: 0,
  overallRating: 0,
});
const showAdditionalEvaluations = ref(false);
const computedRating = computed(() => {
  const base = form.value.deliveryTimelinessRating + form.value.shippingRating + form.value.qualityRating +
    form.value.packagingRating + form.value.overallRating;

  return base / MAX_RATING;
});

watch(showAdditionalEvaluations, (newShowAdditionalEvaluations) => {
  if (newShowAdditionalEvaluations) {
    form.value.rating = 0;
  } else {
    form.value.deliveryTimelinessRating = 0;
    form.value.shippingRating = 0;
    form.value.qualityRating = 0;
    form.value.packagingRating = 0;
    form.value.overallRating = 0;
  }
});

watch(computedRating, (newRating) => {
  if (showAdditionalEvaluations.value) {
    form.value.rating = round(newRating);
  }
});

const selectedSubOrder = ref({} as SubOrder);
const selectedSubOrderId = computed(() => selectedSubOrder.value?.id);
const currentPage = ref(1);
const queryParams = computed(() => {
  const deliveredStatus = 4;
  let baseQuery = `q[status_eq]=${deliveredStatus}`;
  baseQuery += '&q[maker_maker_reviews_shop_id_null]=true';

  return baseQuery;
});
const { subOrders, subOrdersIsLoading } = useShopSubOrdersQuery(
  computed(() => props.shopId),
  currentPage,
  queryParams,
  computed(() => !!props.shopId),
);

function getSubOrderLabel(subOrder: SubOrder) {
  let baseLabel = '';
  if (!!subOrder?.id) baseLabel += `${subOrder.makerName} - Orden #${subOrder.orderId}`;

  return baseLabel;
}

const createMakerReviewMutation = useMutation(
  () => {
    const data = {
      shopId: props.shopId,
      subOrderId: selectedSubOrderId.value,
      makerId: selectedSubOrder.value.makerId,
      ...form.value,
    };

    return makerReviewsApi.create(data);
  },
  {
    onSuccess: () => {
      emit('reload:makerReviews');
      emit('update:modelValue', false);
    },
  },
);
const isCreateMakerReviewLoading = computed(() => createMakerReviewMutation.isLoading.value);

const { handleSubmit } = useForm();

const onSubmit = handleSubmit(() => {
  createMakerReviewMutation.mutate();
});
</script>
