import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import useSessionStore from 'stores/user-store';
import makerDestinationsApi from 'api/maker-destinations';

export default function useMakerDestinationsQuery(makerId: ComputedRef<number>) {
  const sessionStore = useSessionStore();
  const currentAddress = computed(() => sessionStore.currentAddress);
  const regionId = computed(() => currentAddress.value?.regionId || sessionStore.currentRegionId);
  const communeId = computed(() => currentAddress.value?.communeId || sessionStore.currentCommuneId);
  const makerDestinationsQuery = useQuery(
    ['maker-destinations', makerId],
    () => makerDestinationsApi.index(makerId.value),
    {
      enabled: computed(() => !!makerId.value),
    },
  );
  const makerDestinationsQueryIsLoading = computed(() => makerDestinationsQuery.isLoading.value);
  const makerDestinations = computed(() =>
    makerDestinationsQuery.data.value?.data?.makerDestinations as MakerDestination[]);
  const currentMakerDestination = computed(
    () => makerDestinations.value?.find((makerDestination) =>
      (makerDestination.destinationableId === regionId.value && makerDestination.destinationableType === 'Region') ||
      (makerDestination.destinationableId === communeId.value && makerDestination.destinationableType === 'Commune')),
  );

  return { makerDestinations, makerDestinationsQuery, makerDestinationsQueryIsLoading, currentMakerDestination };
}
