<template>
  <div
    class="flex w-full flex-col rounded-md border text-gray-700"
    :class="{ 'border-gray-300': subOrder.incidenceStatus !== 'incidence_in_progress',
              'border-red-500': subOrder.incidenceStatus === 'incidence_in_progress' }"
  >
    <component
      :is="inSubOrderShowPath ? 'button' : 'a'"
      class="flex w-full space-x-3 space-y-1 rounded-md px-3 pb-2 pt-3 hover:bg-gray-50 sm:items-center md:space-y-0 md:px-4"
      :class="{ 'rounded-t-md': firstSubOrder, 'rounded-b-md': lastSubOrder && !showDetails }"
      :href="`/sub_orders/${subOrder.id}`"
      target="_blank"
      @click="inSubOrderShowPath ? showDetails = !showDetails : null"
    >
      <div class="flex w-full flex-col">
        <div class="flex flex-col md:flex-row md:items-center md:justify-between">
          <sub-order-list-card-icons
            :sub-order="subOrder"
            :maker="maker"
            class="mb-1 md:mb-0"
          />
          <div class="flex items-center">
            <div
              class="mr-1 flex items-center justify-center rounded-full border border-gray-700 bg-white p-1 text-center text-xs text-gray-700 sm:mr-2 md:ml-2 md:text-sm"
            >
              {{ subOrder.commission }}%
            </div>
            <span
              class="shrink-0 rounded-full bg-lk-green p-1 text-center text-xs uppercase text-white sm:px-2 md:text-sm"
              :class="{
                'bg-red-600': subOrder.status === 'pending',
                'bg-yellow-500': subOrder.status === 'confirmed',
                'bg-lk-light-purple': ['delivery_in_progress', 'ready_for_pickup'].includes(subOrder.status),
              }"
            >
              <template v-if="subOrder.shipmentMethod === 'shop_pickup' && subOrder.status === 'confirmed'">
                Pendiente de retiro
              </template>
              <template v-else>
                {{ $t(`subOrder.status.${camelCase(subOrder.status)}`) }}
              </template>
            </span>
          </div>
        </div>
        <div class="flex w-full flex-col md:flex-row md:items-center">
          <div class="flex w-full items-center">
            <div class="flex flex-col">
              <p class="self-start text-base md:text-lg">
                <span>{{ subOrder.shop.name }}</span>
              </p>
              <p class="mb-2 flex flex-wrap items-center space-x-1 text-sm font-light md:text-base">
                <span>{{ rutFormat(subOrder.shop.businessRut) }}</span>
                <span>·</span>
                <lokal-loading
                  v-if="subOrderProductsLoading"
                  :loading="subOrderProductsLoading"
                  size="xs"
                />
                <span v-else>{{ subOrderItems }} items</span>
                <lokal-loading
                  v-if="orderAddressLoading"
                  :loading="orderAddressLoading"
                  size="xs"
                />
                <template v-else>
                  <span>·</span>
                  <lokal-tooltip
                    position-options="center"
                    information-classes="w-64 md:w-80"
                    :information="`${orderAddress?.communeName}, ${orderAddress?.regionName}`"
                  >
                    <template #custom-content>
                      <span v-if="isMobile">{{ orderAddress?.regionCode }}</span>
                      <span v-else>{{ orderAddress?.regionName }}</span>
                    </template>
                  </lokal-tooltip>
                </template>
              </p>
            </div>
            <button
              class="ml-auto"
              @click.prevent.stop="showDetails = !showDetails"
            >
              <ChevronDownIcon
                class="size-5 md:size-6"
                :class="{ 'rotate-180 duration-150 ease-linear': showDetails,
                          'duration-150 ease-linear': !showDetails }"
              />
            </button>
          </div>
        </div>
        <div
          v-if="subOrderProductsLoading"
          class="flex h-full"
        >
          <lokal-loading
            v-model="subOrderProductsLoading"
            size="xs"
          />
        </div>
        <sub-order-button-action
          v-else
          :sub-order="subOrder"
          :maker="maker"
          class="w-full"
          :sub-order-products="subOrderProducts"
          @update:sub-order="() => emit('update:subOrder')"
        />
      </div>
    </component>
    <div
      v-if="showDetails"
      class="flex flex-col space-y-1 px-3 py-2 text-gray-700"
      :class="{ 'rounded-b-md': lastSubOrder }"
    >
      <div
        v-if="!!subOrder.shop.businessRut"
        class="flex items-center space-x-2 uppercase"
      >
        <p class="text-xs text-gray-400 md:text-sm">
          Rut:
        </p>
        <p class="text-xs md:text-sm">
          {{ rutFormat(subOrder.shop.businessRut) }}
        </p>
        <clipboard-button
          :text="subOrder.shop.businessRut"
          text-name="Rut"
        />
      </div>
      <div
        v-if="!!subOrder.shop.contactName"
        class="flex items-center space-x-2 uppercase"
      >
        <p class="text-xs text-gray-400 md:text-sm">
          Nombre completo de contacto:
        </p>
        <p class="text-xs md:text-sm">
          {{ subOrder.shop.contactName }}
        </p>
        <clipboard-button
          :text="subOrder.shop.contactName"
          text-name="Nombre completo de contacto"
        />
      </div>
      <div
        v-if="!!subOrder.shop.contactNumber"
        class="flex items-center space-x-2 uppercase"
      >
        <p class="text-xs text-gray-400 md:text-sm">
          Número de contacto:
        </p>
        <p class="text-xs md:text-sm">
          {{ subOrder.shop.contactNumber }}
        </p>
        <clipboard-button
          :text="subOrder.shop.contactNumber"
          text-name="Número de contacto"
        />
      </div>
      <div
        v-if="!!subOrder.shop.contactEmail"
        class="flex items-center space-x-2 uppercase"
      >
        <p class="text-xs text-gray-400 md:text-sm">
          Email de contacto:
        </p>
        <p class="text-xs md:text-sm">
          {{ subOrder.shop.contactEmail }}
        </p>
        <clipboard-button
          :text="subOrder.shop.contactEmail"
          text-name="Email de contacto"
        />
      </div>
      <div class="flex items-center space-x-2 uppercase">
        <p class="text-xs text-gray-400 md:text-sm">
          Razón Social:
        </p>
        <p class="text-xs md:text-sm">
          {{ subOrder.shop.socialReason }}
        </p>
        <clipboard-button
          :text="subOrder.shop.socialReason"
          text-name="Razón social"
        />
      </div>
      <div class="flex items-center space-x-2 uppercase">
        <p class="text-xs text-gray-400 md:text-sm">
          Giro:
        </p>
        <p class="text-left text-xs md:text-sm">
          {{ subOrder.shop.commercialBusiness }}
        </p>
        <clipboard-button
          :text="subOrder.shop.commercialBusiness"
          text-name="Giro"
        />
      </div>
      <div class="flex items-center space-x-2 uppercase">
        <p class="text-xs text-gray-400 md:text-sm">
          Dirección:
        </p>
        <lokal-loading
          v-if="orderAddressLoading"
          :loading="orderAddressLoading"
          size="xs"
        />
        <p
          v-else
          class="text-left text-xs md:text-sm"
        >
          {{ orderAddress?.address }}, {{ orderAddress?.observations }}
        </p>
        <clipboard-button
          :text="`${orderAddress?.address}, ${orderAddress?.observations}`"
          text-name="Dirección"
        />
      </div>
      <p class="flex items-center space-x-2 uppercase">
        <span class="text-xs text-gray-400 md:text-sm">Comuna/Región:</span>
        <lokal-loading
          v-if="orderAddressLoading"
          :loading="orderAddressLoading"
          size="xs"
        />
        <span
          v-else
          class="text-left text-xs md:text-sm"
        >
          {{ orderAddress?.communeName }}, {{ orderAddress?.regionName }}
        </span>
      </p>
      <p class="flex items-center space-x-2 uppercase">
        <span class="text-xs text-gray-400 md:text-sm">Estado de pago:</span>
        <span
          class="text-left text-xs md:text-sm"
        >
          {{ $t(`subOrder.paymentStatus.${camelCase(subOrder.paymentStatus)}`) }}
        </span>
      </p>
      <p class="flex items-center space-x-2 uppercase">
        <span class="text-xs text-gray-400 md:text-sm">Número de SKU's:</span>
        <lokal-loading
          v-if="subOrderProductsLoading"
          :loading="subOrderProductsLoading"
          size="xs"
        />
        <span
          v-else
          class="text-xs md:text-sm"
        >{{ subOrderProductsCount }}</span>
      </p>
      <p class="flex items-center space-x-2 uppercase">
        <span class="text-xs text-gray-400 md:text-sm">Número de items:</span>
        <lokal-loading
          v-if="subOrderProductsLoading"
          :loading="subOrderProductsLoading"
          size="xs"
        />
        <span
          v-else
          class="text-xs md:text-sm"
        >{{ subOrderItems }}</span>
      </p>
      <div class="flex items-center space-x-2">
        <lokal-loading
          v-if="subOrderProductsLoading"
          :loading="subOrderProductsLoading"
          size="xs"
        />
        <template v-else>
          <lokal-tooltip
            :position-options="isMobile ? 'left' : 'center'"
            information-classes="w-64 md:w-80"
            button-classes="flex items-center space-x-1 text-xs uppercase md:text-sm text-gray-400"
            main-label="Peso del pedido (kg):"
            :information="weightInformation"
          />
          <span
            class="text-xs md:text-sm"
          >{{ round(subOrderProductsWeight, 2) }}</span>
        </template>
      </div>
      <button
        v-if="subOrder.totalWholesaleAmountCents"
        class="flex items-center space-x-2 text-left"
        @click="showTotalsDetails = !showTotalsDetails"
      >
        <lokal-tooltip
          :position-options="isMobile ? 'left' : 'center'"
          information-classes="w-64 md:w-80"
          button-classes="flex items-center space-x-1 text-xs text-left uppercase md:text-sm text-gray-400"
          main-label="Monto total neto Lokal:"
          :information="`El 'monto total neto Lokal' es el monto total neto de la venta menos la comisión de Lokal`"
        />
        <span class="text-xs md:text-sm">{{ useFormatCurrency(amountToBill) }}</span>
        <ChevronDownIcon
          class="size-3 shrink-0 md:size-4"
          :class="{ 'rotate-180 duration-150 ease-linear': showTotalsDetails,
                    'duration-150 ease-linear': !showTotalsDetails }"
        />
      </button>
      <div
        v-if="showTotalsDetails"
        class="flex flex-col px-5 text-gray-400"
      >
        <p
          v-if="totalWholesaleWithoutCommission"
          class="flex items-center space-x-2"
        >
          <span class="text-xs text-gray-400 md:text-sm">Total neto sin descuentos:</span>
          <span class="text-xs md:text-sm">{{ useFormatCurrency(totalWholesaleWithoutCommission) }}</span>
        </p>
        <p
          class="flex items-center space-x-2"
        >
          <span class="text-xs text-gray-400 md:text-sm">Descuento total productos:</span>
          <span class="text-xs md:text-sm">-{{ useFormatCurrency(subOrder.totalMakerDiscountAmountCents) }}</span>
        </p>
        <p
          class="flex items-center space-x-2"
        >
          <span class="text-xs text-gray-400 md:text-sm">Decto. por volumen:</span>
          <span class="text-xs md:text-sm">-{{ useFormatCurrency(subOrder.volumeDiscountAmountCents) }}</span>
        </p>
        <p
          class="flex items-center space-x-2"
        >
          <span class="text-xs text-gray-400 md:text-sm">Decto. por código:</span>
          <span class="text-xs md:text-sm">-{{ useFormatCurrency(subOrder.makerDiscountCodeAmountCents) }}</span>
        </p>
      </div>
      <button
        v-if="subOrder.totalWholesaleAmountCents"
        class="flex items-center space-x-2 text-left"
        @click="showTaxes = !showTaxes"
      >
        <lokal-tooltip
          :position-options="isMobile ? 'left' : 'center'"
          information-classes="w-64 md:w-80"
          button-classes="flex items-center space-x-1 text-xs text-left uppercase md:text-sm text-gray-400"
          main-label="Impuestos:"
          :information="taxesInformation"
        />
        <span class="text-xs md:text-sm">{{ useFormatCurrency(totalAdditionalTaxAmount + amountToBillIvaAmount) }}</span>
        <ChevronDownIcon
          class="size-3 shrink-0 md:size-4"
          :class="{ 'rotate-180 duration-150 ease-linear': showTaxes,
                    'duration-150 ease-linear': !showTaxes }"
        />
      </button>
      <div
        v-if="showTaxes"
        class="flex flex-col px-5 text-gray-400"
      >
        <template v-for="(additionalTax, key) in groupedAdditionalTaxes">
          <p
            v-if="additionalTax > 0"
            :key="key"
            class="flex items-center space-x-2"
          >
            <span class="text-xs text-gray-400 md:text-sm">{{ additionalTaxInitials(key) }}:</span>
            <span class="text-xs md:text-sm">{{ useFormatCurrency(additionalTax) }}</span>
          </p>
        </template>
        <p class="flex items-center space-x-2">
          <span class="text-xs text-gray-400 md:text-sm">IVA:</span>
          <span class="text-xs md:text-sm">{{ useFormatCurrency(amountToBillIvaAmount) }}</span>
        </p>
      </div>
      <p class="flex items-center space-x-2 uppercase">
        <span class="text-xs text-gray-400 md:text-sm">Despacho gratis {{ subOrder.makerName }}:</span>
        <span class="text-xs md:text-sm">{{ $t(`general.${withFreeShipping}`) }}</span>
      </p>
      <p
        v-if="subOrder.advancePaymentFeeValue > 0"
        class="flex items-center space-x-2 uppercase"
      >
        <span class="text-xs text-gray-400 md:text-sm">Costo de pago adelantado ({{ subOrder.advancePaymentFeeValue }}%):</span>
        <span class="text-xs md:text-sm">{{ useFormatCurrency(subOrder.advancePaymentFeeAmountCents) }}</span>
      </p>
      <div class="flex items-center space-x-2">
        <lokal-tooltip
          :position-options="isMobile ? 'left' : 'center'"
          information-classes="w-64 md:w-80"
          button-classes="flex items-center space-x-1 text-xs text-left uppercase md:text-sm text-gray-400"
          main-label="Fecha estimada de pago:"
          :information="`Debes subir la factura para recibir el pago. Los pagos se hacen los días hábiles.`"
        />
        <span
          v-if="subOrder.paymentDate"
          class="text-xs uppercase md:text-sm"
        >
          {{ timeToDate(subOrder.paymentDate) }}
        </span>
      </div>
      <p
        v-if="subOrder.freeShippingConditions === 'with_lokal'"
        class="flex items-center space-x-2 uppercase"
      >
        <span class="text-xs text-gray-400 md:text-sm">Costo estimado de despacho neto:</span>
        <span class="text-xs md:text-sm">{{ useFormatCurrency(subOrder.originalShippingCostAmountCents) }}</span>
      </p>
      <p
        v-if="subOrder.freeShippingConditions === 'with_lokal'"
        class="flex items-center space-x-2 uppercase"
      >
        <span class="text-xs text-gray-400 md:text-sm">Costo neto de despacho a pagar por {{ subOrder.makerName }}:</span>
        <span class="text-xs md:text-sm">{{ useFormatCurrency(round(subOrder.originalShippingCostAmountCents / 2)) }}</span>
      </p>
      <p class="flex items-center space-x-2 text-xs md:text-sm">
        <span class="uppercase text-gray-400">Link de seguimiento:</span>
        <a
          v-if="subOrder.trackingUrl"
          :href="subOrder.trackingUrl"
          class="underline"
          target="_blank"
          rel="noopener"
        >
          Revisar link
        </a>
      </p>
      <p
        v-if="subOrder.subOrderInvoiceUrls?.length"
        class="mb-2 flex items-center space-x-2"
      >
        <span class="text-xs uppercase text-gray-400 md:text-sm">Factura de compra:</span>
        <a
          v-for="(subOrderInvoiceUrl, index) in subOrder.subOrderInvoiceUrls"
          :key="`sub-order-invoice-url-${index}`"
          :href="subOrderInvoiceUrl"
          class="text-xs underline md:text-sm"
          target="_blank"
          noopener
        >
          Descargar factura
          <span v-if="subOrder.subOrderInvoiceUrls.length > 1">#{{ index + 1 }}</span>
        </a>
      </p>
      <p
        v-if="subOrder.signedSummaryImageUrl"
        class="mb-2 flex items-center space-x-2"
      >
        <span class="text-xs uppercase text-gray-400 md:text-sm">Manifiesto firmado:</span>
        <a
          :href="subOrder.signedSummaryImageUrl"
          class="text-xs underline md:text-sm"
          target="_blank"
          noopener
        >
          Descargar manifiesto
        </a>
      </p>
      <p
        v-if="showDetailsLink"
        class="flex items-center space-x-2 text-xs text-gray-400 md:text-sm"
      >
        <span class="uppercase">Más información:</span>
        <a
          :href="`/sub_orders/${subOrder.id}`"
          class="text-gray-700 underline"
          target="_blank"
          noopener
        >
          Ver Detalle
        </a>
      </p>
      <p
        v-if="subOrder.comment"
        class="flex items-center space-x-2 text-xs text-gray-400 md:text-sm"
      >
        <span class="uppercase">Comentario:</span>
        <span>{{ subOrder.comment }}</span>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { computed, ref, inject } from 'vue';
import { rutFormat } from 'rut-helpers';
import { camelCase, sumBy, round, groupBy, keys } from 'lodash';
import { useQuery } from 'vue-query';
import subOrderProductsApi from 'api/sub-order-products';
import useFormatCurrency from './use/format-currency';
import LokalTooltip from './shared/lokal-tooltip.vue';
import SubOrderButtonAction from './sub-order-button-action.vue';
import SubOrderListCardIcons from './sub-order-list-card-icons.vue';
import useDateTools from './use/date-tools';
import useBillingTools from './use/billing-tools';
import useOrderAddressQuery from './queries/order-address-query';
import clipboardButton from './shared/clipboard-button.vue';

const GRAMS_TO_KILOGRAMS = 1000;

interface SubOrderListCardProps {
  subOrder: SubOrder,
  maker: Maker
  firstSubOrder: boolean,
  lastSubOrder: boolean,
  showDetailsLink: boolean,
  showDetailsOpened?: boolean,
}

const props = withDefaults(defineProps<SubOrderListCardProps>(), {
  showDetailsLink: false,
  showDetailsOpened: false,
});

const isMobile = inject('isMobile');

const emit = defineEmits<{(e:'update:subOrder'): void}>();
const showDetails = ref(props.showDetailsOpened);
const showTotalsDetails = ref(false);
const { billTools, additionalTaxInitials } = useBillingTools();
const { timeToDate } = useDateTools();

const commissionFraction = computed(() => props.subOrder.commission / billTools.value.totalPorcentage);
const makerDiscountedAmount = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.totalMakerDiscountAmountCents);
const makerDiscountedAmountWithoutVolume = computed(() =>
  makerDiscountedAmount.value - props.subOrder.volumeDiscountAmountCents - props.subOrder.makerDiscountCodeAmountCents);
const totalCommission = computed(() => makerDiscountedAmountWithoutVolume.value * commissionFraction.value);
const totalWholesaleWithoutCommission = computed(() =>
  props.subOrder.totalWholesaleAmountCents - totalCommission.value);
const amountToBill = computed(() => makerDiscountedAmountWithoutVolume.value - totalCommission.value);
const amountToBillIvaAmount = computed(() => amountToBill.value * billTools.value.iva);

const subOrderProductsQuery = useQuery(['SubOrderProducts', props.subOrder.id],
  () => subOrderProductsApi.index(props.subOrder.id));
const subOrderProductsLoading = computed(() => subOrderProductsQuery.isLoading.value);
const subOrderProducts = computed(() => subOrderProductsQuery.data.value?.data.subOrderProducts as SubOrderProduct[]);
const subOrderItems = computed(() =>
  sumBy(subOrderProducts.value, (subOrderProduct) => subOrderProduct.quantity),
);
function weightToKilograms(subOrderProduct: SubOrderProduct) {
  if (subOrderProduct.product.weightUnit === 'grams') {
    return (subOrderProduct.product.weight * subOrderProduct.quantity) / GRAMS_TO_KILOGRAMS;
  }

  return subOrderProduct.product.weight * subOrderProduct.quantity;
}
const subOrderProductsWeight = computed(() =>
  sumBy(subOrderProducts.value, (subOrderProduct) => weightToKilograms(subOrderProduct)),
);
const subOrderProductsCount = computed(() =>
  subOrderProducts.value?.length,
);

const showTaxes = ref(false);
const groupedAdditionalTaxes = computed(() => {
  const groupedTaxes = groupBy(subOrderProducts.value, 'additionalTax');

  return keys(groupedTaxes).reduce(
    (newHash, taxKey: string) => {
      const sumAdditionalTaxes = sumBy(groupedTaxes[taxKey],
        (subOrderProduct) => subOrderProduct.shopAdditionalTaxAmountCents * subOrderProduct.quantity);

      return { ...newHash, [`${taxKey}`]: sumAdditionalTaxes };
    }, {});
});
const totalAdditionalTaxAmount = computed(() =>
  sumBy(subOrderProducts.value, (subOrderProduct) =>
    subOrderProduct.shopAdditionalTaxAmountCents * subOrderProduct.quantity),
);

const orderId = computed(() => props.subOrder.orderId);
const { orderAddress, orderAddressIsLoading: orderAddressLoading } = useOrderAddressQuery(orderId);

const inSubOrderShowPath = computed(() => /^\/sub_orders\/\d+$/.test(window.location.pathname));

const weightInformation = computed(() => `El peso total depende de la cantidad de productos y el peso individual de
  cada producto. Si los pesos de los productos no están actualizados este valor estará incorrecto.`);
const taxesInformation = computed(() => `Algunos productos como las bebidas azucaradas (IABA) o productos
  alcóholicos (ILA) tienen impuestos adicionales.`);

const withFreeShipping = computed(() =>
  ['free_shipping_with_lokal', 'free_shipping_by_maker'].includes(props.subOrder.shippingConditions));
</script>
