<template>
  <div class="relative flex w-full flex-col items-center bg-gray-50 pb-10">
    <lokal-loading
      v-if="subOrderQueryisLoading"
      :loading="subOrderQueryisLoading"
    />
    <template v-else>
      <sub-order-show-status-bar :sub-order="currentSubOrder" />
      <div class="mb-10 flex w-full max-w-3xl flex-col rounded-md bg-white px-5 py-3 md:p-10">
        <div class="mb-3 flex items-center justify-between">
          <a
            href="/makers/account?tab=sub_orders"
            class="flex items-center text-gray-700 hover:underline"
          >
            <ChevronLeftIcon class="mr-2 size-4 md:size-5" />
            <span class="text-sm md:text-base">Volver</span>
          </a>
          <lokal-popover-dropdown
            :labels="popoverLabels"
            :hrefs="popoverHrefs"
            :prop-components="propComponets"
            :components="components"
            main-label="Acciones"
          />
        </div>
        <div class="mb-2 flex flex-col md:mb-3 md:flex-row md:items-center md:space-x-2">
          <button
            v-if="currentSubOrder.advancePaymentFeeValue == 0 && maker.advancePaymentEnabled &&
              currentSubOrder.paymentStatus !== 'paid'"
            class="mb-1 flex w-full items-center justify-center rounded-md border border-lk-green p-1 text-sm text-lk-green hover:bg-lk-green hover:text-white md:mb-0 md:ml-auto md:w-auto md:p-2 md:text-base"
            @click="showAdvancePaymentDialog = !showAdvancePaymentDialog"
          >
            Adelantar pago de factura
          </button>
          <div
            v-if="(invoiceExists && currentSubOrder?.paymentStatus === 'paid')"
            class="flex w-full items-center justify-center rounded-full border border-lk-green p-1 text-sm text-lk-green md:ml-auto md:w-auto md:p-2 md:text-base"
          >
            <span>Factura Pagada</span>
            <CheckBadgeIcon
              v-if="currentSubOrder.paymentStatus === 'paid'"
              class="ml-1 h-5 w-4 shrink-0 text-lk-green md:size-5"
            />
          </div>
          <button
            v-else
            rel="nofollow"
            class="flex w-full items-center justify-center rounded-md border border-lk-green p-1 text-sm text-lk-green md:ml-auto md:w-auto md:p-2 md:text-base"
            :class="{ 'hover:bg-lk-green hover:text-white': currentSubOrder.paymentStatus !== 'paid' }"
            @click="uploadBillDialogOpen = !uploadBillDialogOpen"
          >
            <ArrowUpTrayIcon class="mr-2 size-4 md:size-5" />
            <span>{{ invoiceButtonText }}</span>
          </button>
          <button
            v-if="false"
            class="flex w-full items-center justify-center rounded-md border border-lk-green p-1 text-sm text-lk-green md:ml-auto md:w-auto md:p-2 md:text-base"
            @click="showBillListDialog = !showBillListDialog"
          >
            <ArrowUpTrayIcon class="mr-2 size-4 md:size-5" />
            <span>Nueva factura</span>
          </button>
        </div>
        <sub-order-show-incidence-report-list
          v-if="incidenceReportEnabled"
          :sub-order="currentSubOrder"
        />
        <sub-order-list-card
          :sub-order="currentSubOrder"
          :maker="maker"
          :last-sub-order="true"
          :first-sub-order="true"
          :show-details-link="false"
          :show-details-opened="true"
          class="mb-2"
          @update:sub-order="async () => await subOrderQuery.refetch.value()"
        />
        <button
          v-if="!isMobile"
          class="my-2 ml-auto flex items-center text-xs text-gray-700 hover:underline md:text-sm"
          @click="showDiscountDetails = !showDiscountDetails"
        >
          <span class="mr-2">{{ showDiscountDetails ? 'Resumir descuentos' : 'Mostrar descuentos' }}</span>
          <ChevronDownIcon
            class="size-4 shrink-0"
            :class="{ 'rotate-180 duration-300': showDiscountDetails, 'duration-300': !showDiscountDetails }"
          />
        </button>
        <div class="flex flex-col items-center space-y-2 rounded-md text-sm">
          <div
            v-if="!isMobile"
            class="grid w-full place-items-center gap-x-0 overflow-x-auto rounded-md bg-lk-green py-3 text-white"
            :class="{
              'grid-cols-9 transition-all duration-300': showDiscountDetails && !advancePaymentFeePresent,
              'grid-cols-10 transition-all duration-300': showDiscountDetails && advancePaymentFeePresent,
              'grid-cols-7 transition-all duration-300': !showDiscountDetails && !advancePaymentFeePresent,
              'grid-cols-8 transition-all duration-300': !showDiscountDetails && advancePaymentFeePresent,
            }"
          >
            <p class="px-2 text-center">
              Imagen
            </p>
            <p class="px-2 text-center">
              Producto
            </p>
            <p class="px-2 text-center">
              Precio mayorista <br> (neto)
            </p>
            <transition
              enter-active-class="transform-all duration-300"
              enter-from-class="-translate-x-20 opacity-0"
              leave-active-class="transform-all duration-0"
              leave-to-class="-translate-x-20 opacity-0"
            >
              <div
                v-if="showDiscountDetails"
                class="col-span-3 grid grid-cols-3 place-items-center"
              >
                <p class="px-2 text-center">
                  Descuento {{ currentSubOrder.makerName }}
                </p>
                <p class="px-2 text-center">
                  Descuento x Volumen ({{ currentSubOrder.volumeDiscountValue }}%)
                </p>
                <p class="px-2 text-center">
                  Descuento x Código ({{ makerDiscountValue }}%)
                </p>
              </div>
            </transition>
            <transition
              enter-active-class="transform-all duration-300"
              enter-from-class="-translate-x-20 opacity-0"
              leave-active-class="transform-all duration-0"
              leave-to-class="-translate-x-20 opacity-0"
            >
              <button
                v-if="!showDiscountDetails"
                class="flex items-center px-2 text-center"
                @click="showDiscountDetails = !showDiscountDetails"
              >
                <span class="mr-0">Total descuentos</span>
              </button>
            </transition>
            <p class="px-2 text-center">
              Comisión ({{ currentSubOrder.commission }}%)
            </p>
            <p
              v-if="advancePaymentFeePresent"
              class="px-2 text-center"
            >
              Costo pago anticipado
            </p>
            <p class="px-2 text-center">
              Monto a facturar <br> (neto)
            </p>
            <p class="px-2 text-center">
              Unidades vendidas
            </p>
          </div>
          <sub-order-show-sub-order-product-card
            v-for="subOrderProduct in subOrderProducts"
            :key="subOrderProduct.id"
            :show-discount-details="showDiscountDetails"
            :advance-paymente-fee-present="advancePaymentFeePresent"
            :sub-order="currentSubOrder"
            :sub-order-product="subOrderProduct"
          />
        </div>
        <sub-order-show-upload-bill-dialog
          v-if="uploadBillDialogOpen"
          v-model="uploadBillDialogOpen"
          :sub-order-id="currentSubOrder.id"
          :sub-order="currentSubOrder"
          :maker="maker"
          @invoice-created="async () => await subOrderQuery.refetch.value()"
          @update:sub-order="async () => await subOrderQuery.refetch.value()"
        />
        <sub-order-bill-list-dialog
          v-if="showBillListDialog"
          v-model="showBillListDialog"
          :sub-order="currentSubOrder"
          :maker="maker"
        />
        <sub-order-advance-payment-dialog
          v-if="showAdvancePaymentDialog"
          v-model="showAdvancePaymentDialog"
          :sub-order="currentSubOrder"
          :maker="maker"
          @update:sub-order="async () => await subOrderQuery.refetch.value()"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { CheckBadgeIcon, ChevronLeftIcon, ChevronDownIcon, ArrowUpTrayIcon } from '@heroicons/vue/24/solid';
import { inject, ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import useSubOrderQuery from './queries/sub-order-query';
import subOrderListCard from './sub-order-list-card.vue';
import SubOrderShowSubOrderProductCard from './sub-order-show-sub-order-product-card.vue';
import SubOrderShowUploadBillDialog from './sub-order-show-upload-bill-dialog.vue';
import downloadableFilesForm from './shared/downloadable-files-form.vue';
import lokalPopoverDropdown from './shared/lokal-popover-dropdown.vue';
import SubOrderShowStatusBar from './sub-order-show-status-bar.vue';
import useDiscountCodeQuery from './queries/discount-code-query';
import subOrderShowIncidenceReportList from './sub-order-show-incidence-report-list.vue';
import SubOrderAdvancePaymentDialog from './sub-order-advance-payment-dialog.vue';
import subOrderBillListDialog from './sub-order-bill-list-dialog.vue';

const props = defineProps<{
  order: Order,
  subOrder: SubOrder,
  subOrderProducts: SubOrderProduct[],
  maker: Maker,
  carriers?: Carrier[]
}>();

const {
  subOrder: currentSubOrder, subOrderQuery, subOrderQueryisLoading,
} = useSubOrderQuery(computed(() => props.subOrder.id));

const incidenceReportEnabled = computed(() =>
  ['incidence_in_progress', 'incidence_resolved'].includes(currentSubOrder.value?.incidenceStatus));

const stickyElement = ref(null);
const isStickyElement = ref(false);
useIntersectionObserver(
  stickyElement,
  ([entry]) => {
    isStickyElement.value = entry.intersectionRatio < 1;
  },
  { threshold: 1 },
);

const isMobile = inject('isMobile');
const showDiscountDetails = ref(false);
const advancePaymentFeePresent = computed(() => currentSubOrder.value?.advancePaymentFeeValue > 0);
const showAdvancePaymentDialog = ref(false);
const showBillListDialog = ref(false);
const uploadBillDialogOpen = ref(false);

const invoiceExists = computed(() => currentSubOrder.value?.subOrderInvoiceUrls?.length);

const invoiceButtonText = computed(() => {
  if (invoiceExists.value) return 'Editar Factura';

  return 'Subir Factura';
});

const withWarehousePickup = computed(() =>
  ['with_pickup', 'only_summary'].includes(props.maker.warehousePickupCondition));

const popoverLabels = computed(() => {
  const labels = ['Guía de despacho', 'Etiqueta de despacho'];
  if (withWarehousePickup.value) labels.push('Manifiesto');

  return labels;
});

const popoverHrefs = computed(() => {
  const hrefs = [currentSubOrder.value?.shipmentInvoiceUrl, currentSubOrder.value?.labelDocumentUrl];
  if (withWarehousePickup.value) hrefs.push(currentSubOrder.value?.summaryDocumentUrl);

  return hrefs;
});

const components = [downloadableFilesForm];
const propComponets = computed(() => [
  { action: `/sub_orders/${currentSubOrder.value?.id}/download_excel`, label: 'Descargar excel facturable',
    classes: 'w-full hover:font-semibold md:w-auto' },
]);

const computedDiscountCodeId = computed(() => currentSubOrder.value?.discountCodeId);
const discountCodeQueryEnabled = computed(() => !!currentSubOrder.value?.discountCodeId);
const { discountCode } = useDiscountCodeQuery(computedDiscountCodeId, discountCodeQueryEnabled);
const makerDiscountValue = computed(() => discountCode.value?.makerDiscount || 0);
</script>
