<template>
  <div class="relative">
    <div class="mb-2 flex items-baseline justify-between">
      <h2
        v-if="makers && makers.length && makers.length > 0"
        class="text-lg sm:text-xl md:text-2xl"
      >
        {{ title }}
      </h2>
      <div class="flex gap-1">
        <button
          v-for="page in numberOfPages"
          :key="`page-${page}`"
          type="button"
          class="h-0.5 w-4 rounded-sm md:h-1 md:w-8"
          :class="page === currentPage + 1 ? 'bg-gray-300' : 'bg-gray-200'"
          @click="currentPage = page - 1"
        />
      </div>
    </div>

    <div
      v-if="makersIsLoading"
      class="mb-3 flex items-baseline gap-2 overflow-x-auto sm:gap-3 md:mb-5 md:gap-4 lg:gap-5 xl:gap-6"
    >
      <div
        v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        :key="`shop-landing-last-makers-placeholder-${i}`"
        class="flex shrink-0 flex-col items-center justify-center py-3 hover:opacity-50 md:py-5"
      >
        <div
          class="aspect-[3/2] h-32 w-full shrink-0 rounded-sm object-cover sm:h-36 md:h-40 lg:h-44 xl:h-48"
        >
          <div class="h-full animate-shimmer rounded-md bg-gradient-to-r from-gray-200 via-white to-gray-200 bg-[length:200%_100%]" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="relative"
    >
      <button
        v-if="currentPage > 0"
        class="absolute left-0 top-0 z-10 flex h-32 w-12 items-center justify-center rounded-l-sm bg-gradient-to-r from-gray-700/50 to-transparent hover:to-gray-700/50 sm:h-36 md:h-40 lg:h-44 xl:h-48"
        type="button"
        @click="prevPage"
      >
        <ChevronLeftIcon class="size-8 text-white" />
      </button>
      <div
        class="relative overflow-hidden"
      >
        <div
          class="flex items-baseline transition-transform duration-500 ease-out"
          :style="{ transform: `translateX(-${currentPage * 100}%)` }"
        >
          <template
            v-for="makerChunk in makerChunks"
            :key="`chunk-${makerChunk?.[0]?.id}`"
          >
            <div
              class="flex w-full shrink-0 items-baseline gap-2 overflow-x-hidden sm:gap-3 md:gap-4 lg:gap-5 xl:gap-6"
            >
              <shop-landing-maker-card
                v-for="maker in makerChunk"
                :key="`${baseKey}-${maker.id}`"
                :maker="maker"
                :base-key="`${baseKey}-${maker.id}`"
                class="shrink-0"
                :show-badge="showBadge"
                :badge-text="badgeText"
                :badge-kind="badgeKind"
              />
              <shop-landing-maker-card
                v-if="nextPageFirstMaker && makerChunks && makerChunks?.length > 1 && currentPage !== numberOfPages - 1"
                :key="`${baseKey}-next-page-first-maker-${nextPageFirstMaker.id}`"
                :maker="nextPageFirstMaker"
                :base-key="`${baseKey}-${nextPageFirstMaker.id}`"
                class="shrink-0 opacity-50"
                :show-badge="showBadge"
                :badge-text="badgeText"
                :badge-kind="badgeKind"
              />
            </div>
          </template>
        </div>
      </div>
      <button
        v-if="hasNextPage"
        class="absolute right-0 top-0 z-10 flex h-32 w-12 items-center justify-center rounded-r-sm bg-gradient-to-l from-gray-700/50 to-transparent hover:to-gray-700/50 sm:h-36 md:h-40 lg:h-44 xl:h-48"
        type="button"
        @click="nextPage"
      >
        <ChevronRightIcon class="size-8 text-white" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { chunk } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import shopLandingMakerCard from './shop-landing-maker-card.vue';

const TWO_XL_BREAKPOINT = 1536;
const XL_BREAKPOINT = 1280;
const SM_BREAKPOINT = 640;
const TWO_XL_ITEMS_PER_PAGE = 6;
const XL_ITEMS_PER_PAGE = 5;
const SM_ITEMS_PER_PAGE = 3;
const DEFAULT_ITEMS_PER_PAGE = 2;

interface ShopLandingMakersDisplayProps {
  title: string;
  makers: Maker[];
  makersIsLoading: boolean;
  baseKey: string;
  showBadge?: boolean;
  badgeText?: string;
  badgeKind?: string;
}

const props = withDefaults(defineProps<ShopLandingMakersDisplayProps>(), {
  showBadge: false,
  badgeText: '',
  badgeKind: '',
});

const currentPage = ref(0);

const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

function handleResize() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const itemsPerPage = computed(() => {
  if (windowWidth.value >= TWO_XL_BREAKPOINT) return TWO_XL_ITEMS_PER_PAGE;
  if (windowWidth.value >= XL_BREAKPOINT) return XL_ITEMS_PER_PAGE;
  if (windowWidth.value >= SM_BREAKPOINT) return SM_ITEMS_PER_PAGE;

  return DEFAULT_ITEMS_PER_PAGE;
});

const makerChunks = computed(() => chunk(props.makers || [], itemsPerPage.value));

const numberOfPages = computed(() => makerChunks.value.length);
const hasNextPage = computed(() => currentPage.value < numberOfPages.value - 1);

function nextPage() {
  if (hasNextPage.value) {
    currentPage.value++;
  }
}

function prevPage() {
  if (currentPage.value > 0) {
    currentPage.value--;
  }
}

const nextPageFirstMaker = computed(() => {
  if (hasNextPage.value) {
    return makerChunks.value?.[currentPage.value + 1]?.[0];
  }

  return makerChunks.value?.[0]?.[0];
});
</script>
