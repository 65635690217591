<template>
  <div class="mb-10 flex size-full flex-col items-center text-gray-700 md:max-w-7xl md:px-5 lg:px-10">
    <div class="mb-3 flex w-full items-center justify-between px-2 md:px-0">
      <p class="flex flex-col font-serif text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Reseñas
      </p>
      <button
        v-if="!subOrdersIsLoading && subOrders.length > 0"
        class="flex items-center gap-1 rounded-md border border-lk-green bg-white p-1.5 text-sm text-lk-green hover:bg-lk-green hover:text-white focus:outline-none md:p-2 md:text-base"
        @click="showMakerReviewDialog = !showMakerReviewDialog"
      >
        <PlusIcon class="size-4 md:size-5" />
        Crear reseña
      </button>
    </div>
    <div class="flex size-full flex-col rounded-md bg-white px-2 py-3 md:px-4 md:py-5 lg:px-10">
      <div v-if="makerReviewsIsLoading">
        <p>Cargando...</p>
      </div>
      <p v-else-if="makerReviews.length === 0">
        No has hecho ninguna reseña de marcas.
      </p>
      <div v-else>
        <p class="mb-2 text-base sm:mb-3 md:mb-4 md:text-lg">
          Reseñas a marcas hechas por ti.
        </p>
        <lokal-loading
          v-if="makerReviewsIsLoading"
          :loading="makerReviewsIsLoading"
        />
        <div v-else>
          <div
            v-for="makerReview in makerReviews"
            :key="`maker-review-${makerReview.id}`"
            class="mb-2 flex flex-col rounded-md border p-2 md:mb-3"
          >
            <div class="mb-1 flex w-full items-center justify-between">
              <p class="mr-2 text-sm md:text-base">
                {{ makerReview.makerName }}
              </p>
              <star-review
                v-model="makerReview.rating"
                class="mb-0"
                :with-input="false"
                :disabled="true"
              />
            </div>
            <p class="mb-1 text-xs font-light md:text-sm">
              {{ new Date(makerReview.createdAt).toLocaleDateString() }}
            </p>
            <p class="text-xs md:text-sm">
              Comentario:
            </p>
            <p class="mb-2 line-clamp-4 text-xs font-light hover:line-clamp-none md:text-sm">
              {{ makerReview.comment }}
            </p>
            <p class="text-xs md:text-sm">
              Comentario privado:
            </p>
            <p class="line-clamp-4 text-xs font-light hover:line-clamp-none md:text-sm">
              {{ makerReview.privateComment }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <maker-review-new-dialog
      v-model="showMakerReviewDialog"
      :shop-id="shop.id"
      @reload:maker-reviews="async () => await makerReviewsQuery.refetch.value()"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useQuery } from 'vue-query';
import makerReviewsApi from 'api/maker-reviews';
import makerReviewNewDialog from 'components/maker-review-new-dialog.vue';
import starReview from 'components/shared/star-review.vue';
import useShopSubOrdersQuery from './queries/shopSubOrdersQuery';

const props = defineProps<{
  shop: Shop;
}>();

const showMakerReviewDialog = ref(false);
const makerReviewsQuery = useQuery(
  ['maker_reviews', props.shop.id],
  () => makerReviewsApi.shopIndex(props.shop.id),
);
const makerReviewsIsLoading = computed(() => makerReviewsQuery.isLoading.value);
const makerReviews = computed(() => makerReviewsQuery.data.value?.data?.makerReviews as MakerReview[]);

const { subOrders, subOrdersIsLoading } = useShopSubOrdersQuery(computed(() => props.shop.id));
</script>
