<template>
  <div
    v-if="!signUpStore.currentPathIncludesShopsSignUp"
    ref="headerRef"
    class="z-40 flex flex-col"
  >
    <the-header-top-messages />
    <div
      class="flex w-full flex-col items-center justify-between bg-white px-2 pt-4 text-gray-700 shadow-lg sm:justify-start md:px-7 md:pt-6 xl:pl-16"
      :class="{ 'pb-4': currentPathIncludesOrdersCheckout || !sessionStore.user?.id || !isShopUser || isSuperAdmin,
                'pb-2': !currentPathIncludesOrdersCheckout && isShopUser }"
    >
      <div
        v-if="!signUpStore.currentPathIncludesShopsSignUp"
        class="flex w-full items-center justify-between sm:justify-start"
      >
        <LokalUserRegisterDialog
          v-model="menuStore.showUserRegisterDialog"
          :initial-value-sign-in="menuStore.initialValueSignIn"
        />
        <button
          v-if="isMobile && !menuStore.showMenu"
          class="justify-self-start"
          @click="manageShowMenu"
        >
          <Bars3Icon class="size-7 fill-gray-700" />
        </button>
        <button
          v-if="isMobile && menuStore.showMenu"
          class="justify-self-start"
          @click="menuStore.showMenu = !menuStore.showMenu"
        >
          <XMarkIcon class="size-7 fill-gray-700" />
        </button>
        <a
          href="/"
          class="shrink-0 cursor-pointer"
        >
          <img
            src="assets/images/green-k-without-bg.png"
            class="mr-0 h-16 w-auto shrink-0 sm:mr-8 md:mr-16 md:h-[84px]"
          >
        </a>
        <div
          v-if="!isMobile"
          class="flex flex-col items-start"
        >
          <div
            v-if="!isMobile"
            class="flex items-center sm:space-x-2 sm:text-sm md:space-x-3 md:text-base lg:space-x-5 xl:space-x-6 xl:text-lg"
          >
            <a
              v-if="sessionStore.user?.shopId"
              href="/shop_landing"
              :class="{ 'border-b-2 border-lk-green': currentPath.includes('shop_landing') }"
            >
              Descubre
            </a>
            <a
              href="/makers"
              :class="{ 'border-b-2 border-lk-green': currentPath.includes('makers') }"
            >
              Marcas
            </a>
            <a
              href="/categories"
              :class="{ 'border-b-2 border-lk-green': currentPath.includes('categories') }"
            >
              Categorías
            </a>
            <a
              href="/products"
              :class="{ 'border-b-2 border-lk-green': currentPath.includes('products') }"
            >
              Productos
            </a>
            <!-- <a href="/about_us">Sobre Nosotros</a> -->
          </div>
        </div>
        <div class="flex items-center space-x-3 sm:ml-auto md:space-x-5 lg:space-x-9">
          <template v-if="!isMobile">
            <a
              v-if="user?.makerIds?.[0]"
              href="/makers/account?tab=refer"
              class="self-center sm:text-sm md:text-base"
            >
              Invita a tiendas
            </a>
            <template v-if="user?.shopId">
              <lokal-loading
                v-if="isBalanceAccountLoading"
                :loading="isBalanceAccountLoading"
                size="xs"
              />
              <div
                v-else-if="balanceAccount.maximumCreditsApprovedDate"
                class="text-sm md:text-base"
              >
                Créditos: {{ useFormatCurrency(balanceAccount?.creditsLeft) }}
              </div>
            </template>
          </template>
          <button
            v-if="sessionStore.user?.id && !menuStore.showCartSidebar"
            class="self-center"
            @click="manageShowCartSidebar"
          >
            <div
              v-if="cartStore.getItemsLength"
              class="absolute -ml-1 -mt-1 flex size-2 items-center justify-center rounded-full bg-lk-light-purple p-2 text-xs text-white md:-ml-2 md:-mt-2 md:size-5"
            >
              {{ cartStore.getItemsLength }}
            </div>
            <ShoppingBagIcon class="size-7 fill-gray-700 md:size-8" />
          </button>
          <button
            v-if="sessionStore.user?.id && menuStore.showCartSidebar"
            class="justify-self-start"
            @click="menuStore.showCartSidebar = !menuStore.showCartSidebar"
          >
            <XMarkIcon class="size-7 fill-gray-700" />
          </button>
          <button
            v-if="(!isMobile && sessionStore.user?.id && !menuStore.showMenu) || (isMobile && !sessionStore.user?.id)"
            class="ml-auto justify-self-end"
            @click="manageShowMenu"
          >
            <UserCircleIcon class="size-7 text-gray-700 md:size-8" />
          </button>
          <button
            v-if="!isMobile && sessionStore.user?.id && menuStore.showMenu"
            class="justify-self-start"
            @click="menuStore.showMenu = !menuStore.showMenu"
          >
            <XMarkIcon class="size-7 fill-gray-700" />
          </button>
          <div
            v-else-if="!isMobile && !sessionStore.user?.id"
            class="ml-auto flex space-x-3"
          >
            <button
              class="rounded-md border border-lk-green p-2 text-center text-sm text-lk-green lg:px-4 lg:py-2 lg:text-base"
              @click="menuStore.openUserRegisterDialog(true)"
            >
              Iniciar sesión
            </button>
            <button
              class="rounded-md bg-lk-green p-2 text-center text-sm text-white lg:px-4 lg:py-2 lg:text-base"
              @click="menuStore.openUserRegisterDialog(false)"
            >
              Crear Cuenta
            </button>
          </div>
        </div>
      </div>
      <template v-if="!isSuperAdmin">
        <lokal-loading
          v-if="isLoadingShopOrAddress"
          :loading="isLoadingShopOrAddress"
          size="xs"
          class="self-start"
        />
        <the-header-shipping-address-selector
          v-else-if="!currentPathIncludesOrdersCheckout && isShopUser"
          v-model:addresses="addresses"
          :shop="shop"
          @update:shop-and-addresses="shopAndAddressesUpdate"
        />
        <div
          v-else-if="isMakerUser && makers?.length && makers?.length > 1"
          class="ml-auto"
        >
          <lokal-generic-combobox
            v-model="selectedMaker"
            :options="makers"
            :get-option-label="(maker: Maker) => maker.name"
            :get-option-key="(maker: Maker) => maker.id"
            options-label="Seleccionar marca"
            class="ml-auto"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, onMounted, computed, watch } from 'vue';
import { ShoppingBagIcon, Bars3Icon, UserCircleIcon } from '@heroicons/vue/24/solid';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useQuery } from 'vue-query';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import useSignUpStore from 'stores/sign-up-store';
import useGeneralMenuStore from 'stores/general-menu-store';
import usePotentialMakerContactStore from 'stores/potential-maker-contact-store';
import cartProductsApi from 'api/cart-products';
import productsApi from 'api/products';
import LokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import useUrlMethods from './use/url-methods';
import useBalanceAccountQuery from './queries/balance-account-query';
import useFormatCurrency from './use/format-currency';
import TheHeaderShippingAddressSelector from './the-header-shipping-address-selector.vue';
import useAddressesQuery from './queries/addresses-query';
import useShopQuery from './queries/shop-query';
import useProductWholesalePriceMethods from './use/product-wholesale-price';
import theHeaderTopMessages from './the-header-top-messages.vue';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const isMobile = inject('isMobile');

const props = defineProps<{
  user?: User,
  makers?: Maker[],
}>();

const sessionStore = useSessionStore();
sessionStore.$patch((state) => {
  if (props.user?.id) {
    state.user = props.user;
  }
});

const menuStore = useGeneralMenuStore();
const cartStore = useCartStore();
const potentialMakerContactStore = usePotentialMakerContactStore();
const isSuperAdmin = computed(() => sessionStore.user?.kind === 'super_admin');
const isMakerUser = computed(() => sessionStore.user?.kind === 'maker');
const selectedMaker = ref({} as Maker);

const { getQueryParam, getQueryParams } = useUrlMethods();
const makerReferred = computed(() => getQueryParam('referred'));
const discountCode = computed(() => getQueryParam('discount_code'));
const shopReference = computed(() => getQueryParam('reference'));
const signUpStore = useSignUpStore();
const currentPath = computed(() => window.location.pathname);

const isShopUser = computed(() => sessionStore.user?.id && sessionStore.user?.kind === 'shop');

function manageShowMenu() {
  menuStore.showCartSidebar = false;
  menuStore.showMakerFilterSidebar = false;
  menuStore.showMakerAdminMenu = false;
  menuStore.showShopAdminMenu = false;
  menuStore.showProductFilterSidebar = false;
  menuStore.showMenu = !menuStore.showMenu;
}

function manageShowCartSidebar() {
  menuStore.showMenu = false;
  menuStore.showMakerFilterSidebar = false;
  menuStore.showMakerAdminMenu = false;
  menuStore.showShopAdminMenu = false;
  menuStore.showProductFilterSidebar = false;
  menuStore.showCartSidebar = !menuStore.showCartSidebar;
}

watch(() => makerReferred.value, () => {
  signUpStore.userForm.referredSlug = makerReferred.value;
  sessionStore.referredSlug = makerReferred.value;
});

watch(() => discountCode, () => {
  signUpStore.shopForm.registrationDiscountCodeToken = discountCode.value;
});

const cartProductsQueryEnabled = computed(() => !!sessionStore.user?.id);
const cartProductsQuery = useQuery(
  ['cart-products', sessionStore.user?.id], () => cartProductsApi.index(), {
    enabled: cartProductsQueryEnabled,
    onSuccess: (data) => {
      const cartProducts = data.data.cartProducts as CartProduct[];
      cartProducts.forEach((cartProduct) => {
        if (cartStore.items[cartProduct.productId]) {
          // if exists
        } else {
          productsApi.get(cartProduct.productId).then((response) => {
            const product = response.data.product as Product;
            const { productWholesalePrice } = useProductWholesalePriceMethods(
              product, computed(() => cartProduct.quantity));
            cartStore.items[cartProduct.productId] = {
              product,
              quantity: cartProduct.quantity,
              wholesalePriceCents: productWholesalePrice.value,
            };
          });
        }
      });
    },
  });

function loadRegisterialog() {
  if (makerReferred.value && !sessionStore.user?.id && !window.location.pathname.includes('users')) {
    menuStore.showUserRegisterDialog = true;
    menuStore.initialValueSignIn = false;
    signUpStore.userForm.referredSlug = makerReferred.value;
    signUpStore.shopForm.registrationDiscountCodeToken = discountCode.value;
  }
}

const currentPathIncludesOrdersCheckout = computed(() => window.location.pathname.includes('orders/checkout'));
const isLoadingShopOrAddress = ref(false);
const { shop, shopQuery } = useShopQuery(computed(() => sessionStore.user?.shopId));
const {
  addresses, addressesQuery: newAddressesQuery,
} = useAddressesQuery(computed(() => sessionStore.user?.shopId), computed(() => 'Shop'),
  computed(() => !!sessionStore.user?.shopId));

function userWithReferenceRegionAndCommune() {
  return (sessionStore.user?.shopId && shop.value?.referenceRegionId && shop.value?.referenceCommuneId);
}

function userWithAddress() {
  return (sessionStore.user?.shopId && addresses.value?.length && addresses.value?.length > 0);
}

const isShopUserWithoutCompleteShop = computed(() => {
  if (!sessionStore.user?.id) return false;
  if (sessionStore.user?.kind !== 'shop') return false;
  if (userWithReferenceRegionAndCommune()) return false;
  if (userWithAddress()) return false;

  return true;
});

async function redirectToShopsSignUpOrLoadShopAndAddresses() {
  if (sessionStore.user?.id && sessionStore.user?.kind === 'shop' && sessionStore.user?.shopId) {
    isLoadingShopOrAddress.value = true;
    await Promise.all([shopQuery.suspense(), newAddressesQuery.suspense()]);
    isLoadingShopOrAddress.value = false;
  }
  if (isShopUserWithoutCompleteShop.value && !signUpStore.currentPathIncludesShopsSignUp) {
    signUpStore.currentStep = 'shop';
    window.location.href = '/shops/sign_up';
  }
}

function isUserPresentAndShopKind() {
  return sessionStore.user?.id && sessionStore.user?.kind === 'shop' && !isSuperAdmin.value;
}

async function shopAndAddressesUpdate() {
  await Promise.all([shopQuery.refetch.value(), newAddressesQuery.refetch.value()]);
}

onMounted(async () => {
  if (props.user?.id) sessionStore.reloadUser();
  loadRegisterialog();
  if (makerReferred.value) sessionStore.referredSlug = makerReferred.value;
  if (shopReference.value) potentialMakerContactStore.referenceSlug = shopReference.value;
  if (cartProductsQueryEnabled.value) await cartProductsQuery.suspense();
  if (isUserPresentAndShopKind()) redirectToShopsSignUpOrLoadShopAndAddresses();
});

const {
  balanceAccount, isLoading: isBalanceAccountLoading } = useBalanceAccountQuery(computed(() => props.user?.shopId));

const headerRef = ref(null);
watch(headerRef, () => {
  menuStore.headerRef = headerRef.value;
});

watch(() => selectedMaker.value, () => {
  if (selectedMaker.value?.id) {
    const queryParamsText = Object.entries(getQueryParams()).map(([key, value]) => `${key}=${value}`).join('&');
    window.location.href = `/makers/${selectedMaker.value?.slug}/admin?${queryParamsText}`;
  }
});
</script>
