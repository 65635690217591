import { ref, computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';

export default function useShopSubOrdersQuery(
  shopId: ComputedRef<number>, currentPage = ref(1), queryParams = computed(() => ''),
  enabled = computed(() => true),
) {
  const subOrdersQuery = useQuery(
    ['subOrders', shopId, currentPage, queryParams],
    () => subOrdersApi.shopIndex(shopId.value, currentPage.value, queryParams.value),
    { enabled },
  );
  const subOrders = computed(() => subOrdersQuery.data?.value?.data?.subOrders as SubOrder[]);
  const subOrdersIsLoading = computed(() => subOrdersQuery.isLoading.value);

  return { subOrders, subOrdersQuery, subOrdersIsLoading };
}
