<template>
  <div class="mb-10 flex size-full flex-col items-center md:max-w-7xl md:px-5 lg:px-10">
    <div class="mb-3 flex w-full items-center px-2 md:px-0">
      <p class="flex flex-col self-end font-serif text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Ordenes
      </p>
    </div>
    <div class="flex w-full flex-col rounded-md bg-white px-2 py-3 md:px-4 md:py-5 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center"
      />
      <div
        v-else
        class="mb-10 flex w-full flex-col text-gray-700"
      >
        <div class="mb-3 flex w-full items-center justify-between md:mb-5">
          <h1
            class="text-lg md:text-xl"
          >
            {{ shop.name }}
          </h1>
          <p class="text-sm md:text-base">
            <span>{{ totalResources }}</span>
          </p>
        </div>
        <p
          v-if="orders?.length === 0"
          class="text-base md:text-xl"
        >
          Aún no tienes ordenes
        </p>
        <template v-else>
          <order-list-card
            v-for="(order, index) in orders"
            :key="order.id"
            :order="order"
            class="mb-3"
            :last-order="index === orders.length - 1"
          />
          <pagination-component
            :total-pages="totalPages"
            :current-page="currentPage"
            @set-new-page="updatePage"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import orderListCard from './order-list-card.vue';
import useOrdersQuery from './queries/orders-query';
import useShopQuery from './queries/shop-query';
import paginationComponent from './shared/pagination-component.vue';

interface OrderListProps {
  shopId: number
  withTitle?: boolean
}

const props = withDefaults(defineProps<OrderListProps>(), {
  withTitle: true,
});

const currentPage = ref(1);
const computedShopId = computed(() => props.shopId);
const { shop, shopQuery } = useShopQuery(computedShopId);
const queryParams = computed(() => 'q[s]=created_at desc');
const { ordersQueryIsLoading, orders, totalPages, totalResources } = useOrdersQuery(
  computedShopId, currentPage, queryParams,
);
const isLoading = computed(() => shopQuery.isLoading.value || ordersQueryIsLoading.value);

function updatePage(newPage: number) {
  currentPage.value = newPage;
}
</script>
