<template>
  <div class="flex size-full flex-col bg-gray-50 px-4 py-5 md:p-10">
    <div class="mb-3 flex items-baseline gap-6 overflow-x-auto md:mb-5">
      <a
        v-for="category in categories"
        :key="`shop-landing-category-${category.id}`"
        :href="`/makers?category_ids=${category.id}`"
        class="flex shrink-0 flex-col items-center justify-center py-2 hover:opacity-50 md:py-5"
      >
        <img
          :src="category.image?.derivatives.jpgSm?.url"
          :alt="category.name"
          class="size-16 shrink-0 rounded-full object-cover md:size-24"
        >
        <p class="w-16 break-words text-center text-xs md:w-24 md:text-sm">
          {{ category.name }}
        </p>
      </a>
    </div>
    <shop-landing-makers-display
      :makers="lastMakers"
      :makers-is-loading="lastMakersIsLoading"
      title="Compradas recientemente"
      base-key="shop-landing-last-makers-second"
      class="mb-3 md:mb-5"
    />
    <shop-landing-makers-display
      :makers="makersNotPurchasedWithFreeShippingOrSubsidizedShipping"
      :makers-is-loading="makersNotPurchasedWithFreeShippingOrSubsidizedShippingIsLoading"
      title="Con promoción de despacho"
      base-key="shop-landing-landing-maker-not-purchased-with-free-shipping-or-subsidized-shipping"
      class="mb-3 md:mb-5"
    />
    <shop-landing-makers-display
      :makers="makersNotPurchased"
      :makers-is-loading="makersNotPurchasedIsLoading"
      title="Tal vez no las conoces"
      base-key="shop-landing-last-makers-second"
      class="mb-3 md:mb-5"
    />
    <shop-landing-makers-display
      :makers="discountMakers"
      :makers-is-loading="discountMakersIsLoading"
      title="Marcas con descuentos"
      base-key="shop-landing-maker-discount"
      class="mb-3 md:mb-5"
      :show-badge="true"
      badge-text="Descuento"
      badge-kind="discount"
    />
    <shop-landing-makers-display
      :makers="newMakers"
      :makers-is-loading="newMakersIsLoading"
      title="Nuevas marcas en Lokal"
      base-key="shop-landing-maker-new"
      class="mb-3 md:mb-5"
      :show-badge="true"
      badge-text="Nueva"
      badge-kind="info"
    />
    <div class="flex items-center justify-center">
      <a
        href="/makers"
        class="rounded-md border border-lk-green bg-white p-2 text-sm text-lk-green hover:bg-lk-green hover:text-white md:p-3 md:text-base"
      >
        Revisar todas las marcas
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';
import useSessionStore from 'stores/user-store';
import useActiveMakersQuery from './queries/active-makers-query';
import shopLandingMakersDisplay from './shop-landing-makers-display.vue';

defineProps<{
  categories: Category[];
}>();

const sessionStore = useSessionStore();
const shopId = computed(() => sessionStore.user?.shopId);
const shopEnabled = computed(() => !!shopId.value);
const currentAddress = computed(() => sessionStore.currentAddress);
const currentRegion = computed(() => currentAddress.value?.regionId);

const makersNotPurchasedWithFreeShippingOrSubsidizedShippingQueryParams = computed(() => {
  let base = `q[groupings][0][free_shippings_free_shippables_shippable_id_eq]=${currentAddress.value.regionId}`;
  base += '&q[groupings][0][free_shippings_free_shippables_shippable_type_eq]=Region';
  base += '&q[groupings][0][free_shippings_active_eq]=true';
  // base += '&q[groupings][0][free_shippings_active_eq]=true';
  base += '&q[groupings][1][subsidized_shippings_active_eq]=true';
  base += '&q[m]=or';

  return base;
});
const makersNotPurchasedWithFreeShippingOrSubsidizedShippingQueryEnabled = computed(() => true);
const makersNotPurchasedWithFreeShippingOrSubsidizedShippingQuery = useQuery(
  ['makers-not-purchased-with-free-shipping-or-subsidized-shipping',
    shopId,
    makersNotPurchasedWithFreeShippingOrSubsidizedShippingQueryParams],
  () => shopsApi.makersNotPurchased(
    shopId.value,
    makersNotPurchasedWithFreeShippingOrSubsidizedShippingQueryParams.value,
  ),
  {
    enabled: makersNotPurchasedWithFreeShippingOrSubsidizedShippingQueryEnabled,
  },
);
const makersNotPurchasedWithFreeShippingOrSubsidizedShippingIsLoading = computed(() =>
  makersNotPurchasedWithFreeShippingOrSubsidizedShippingQuery.isLoading.value,
);
const makersNotPurchasedWithFreeShippingOrSubsidizedShipping = computed(() =>
  makersNotPurchasedWithFreeShippingOrSubsidizedShippingQuery.data?.value?.data?.makers as Maker[],
);

const lastMakersQuery = useQuery(
  ['last-makers', shopId],
  () => shopsApi.lastMakers(shopId.value),
  {
    enabled: shopEnabled,
  },
);
const lastMakersIsLoading = computed(() => lastMakersQuery.isLoading.value);
const lastMakers = computed(() => lastMakersQuery.data.value?.data?.makers as Maker[]);

const makerNotPurchasedQueryParams = computed(() => {
  const makersIds = makersNotPurchasedWithFreeShippingOrSubsidizedShipping.value?.map((maker) => maker.id) ||
    [] as Maker[];
  let base = '';
  if (currentRegion.value) base += `current_region_id=${currentRegion.value}&`;
  makersIds.forEach((id) => {
    base += `q[id_not_in][]=${id}&`;
  });

  return base;
});
const makersNotPurchasedQueryEnabled = computed(() =>
  !makersNotPurchasedWithFreeShippingOrSubsidizedShippingIsLoading.value);
const makersNotPurchasedQuery = useQuery(
  ['makers-not-purchased', shopId, makerNotPurchasedQueryParams],
  () => shopsApi.makersNotPurchased(shopId.value, makerNotPurchasedQueryParams.value),
  {
    enabled: makersNotPurchasedQueryEnabled,
  },
);
const makersNotPurchasedIsLoading = computed(() => makersNotPurchasedQuery.isLoading.value);
const makersNotPurchased = computed(() => makersNotPurchasedQuery.data.value?.data?.makers as Maker[]);

const discountMakersQueryParams = computed(() => {
  const currentDate = new Date();
  let base = `current_region_id=${currentRegion.value}&`;
  base += `q[products_discounts_start_date_lteq]=${currentDate.toISOString()}`;
  base += `&q[products_discounts_end_date_gteq]=${currentDate.toISOString()}`;

  return base;
});
const currentPage = ref(1);
const discountMakersQueryEnabled = computed(() => true);
const { makers: discountMakers, makersQueryIsLoading: discountMakersIsLoading } = useActiveMakersQuery(
  currentPage, discountMakersQueryParams, discountMakersQueryEnabled,
);

function addNewMakersQueryDate() {
  const daysAgo = 30;
  const currentDate: Date = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(currentDate.getDate() - daysAgo);
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0];

  return `q[created_at_gteq]=${formattedOneMonthAgo}&q[created_at_lteq]=${formattedCurrentDate}&`;
}

const newMakersQueryParams = computed(() => addNewMakersQueryDate());
const newMakersQueryEnabled = computed(() => true);
const { makers: newMakers, makersQueryIsLoading: newMakersIsLoading } = useActiveMakersQuery(
  currentPage, newMakersQueryParams, newMakersQueryEnabled,
);
</script>
