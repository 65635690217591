<template>
  <div
    class="flex flex-col items-center text-sm text-gray-700 md:grid md:grid-cols-2 md:text-base"
  >
    <template v-if="showSummary">
      <p class="w-full capitalize">
        {{ shop.name }}
      </p>
      <p class="w-full font-light md:row-start-2">
        {{ rutFormat(shop.businessRut) }}
      </p>
      <button
        class="row-start-3 w-full text-left text-sm underline hover:underline md:col-start-2 md:row-start-auto md:text-right md:no-underline"
        type="button"
        @click="showSummary = !showSummary"
      >
        Mostrar más
      </button>
    </template>
    <template v-else>
      <div class="mb-1 flex w-full items-center justify-between text-sm  md:col-span-2 md:text-base">
        <p class="capitalize">
          {{ shop.name }}
        </p>
        <button
          v-if="summary"
          class="text-sm hover:underline"
          type="button"
          @click="showSummary = !showSummary"
        >
          Mostrar menos
        </button>
      </div>
      <div class="mb-2 w-full border-b border-gray-500 md:col-span-2" />
      <p class="mb-2 w-full text-left">
        <span class="">Rut de la empresa</span> <br>
        <span class="font-light">{{ rutFormat(shop.businessRut) }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Razón social</span> <br>
        <span class="font-light">{{ shop.socialReason }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Giro de la empresa</span> <br>
        <span class="font-light">{{ shop.commercialBusiness }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Nombre completo de contacto</span> <br>
        <span class="font-light">{{ shop.contactName }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Email de contacto</span> <br>
        <span class="font-light">{{ shop.contactEmail }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Número de contacto</span> <br>
        <span class="font-light">{{ shop.contactNumber }}</span>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">Página web</span> <br>
        <a
          v-if="shop.webpageLink"
          class="mr-2 text-sm font-light underline"
          :href="useUrlWithHost(shop.webpageLink)"
          target="_blank"
          noopener
        >
          {{ shop.webpageLink }}
        </a>
      </p>
      <p class="mb-2 w-full text-left">
        <span class="">¿Tipo de tienda?</span> <br>
        <span class="font-light">{{ $t(`shop.kind.${camelCase(shop.kind)}`) }}</span>
        <span
          v-if="shop.kind === 'other'"
          class="font-light"
        > - {{ shop.otherKind }}</span>
      </p>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { rutFormat } from 'rut-helpers';
import { camelCase } from 'lodash';
import useUrlWithHost from './use/url-with-host';

interface ShopInformationDisplayProps {
  shop: Shop
  summary?: boolean
}

const props = withDefaults(defineProps<ShopInformationDisplayProps>(), {
  summary: false,
});

const showSummary = ref(props.summary);
</script>
