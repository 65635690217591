import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, queryParams?: string) {
    let query = `maker_id=${makerId}`;
    if (queryParams) query += `&${queryParams}`;

    return api({
      method: 'get',
      url: `/api/internal/purchase_invoice_conciliations?${query}`,
      headers,
    });
  },
  bulkCreate(data: Partial<PurchaseInvoiceConciliation>[]) {
    return api({
      method: 'post',
      url: '/api/internal/purchase_invoice_conciliations/bulk_create',
      headers,
      data: {
        purchaseInvoiceConciliations: data,
      }
    });
  },
  create(data: Partial<PurchaseInvoiceConciliation>) {
    return api({
      method: 'post',
      url: '/api/internal/purchase_invoice_conciliations',
      headers,
      data,
    });
  },
};
