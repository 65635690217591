import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  shopIndex(shopId: number) {
    return api({
      method: 'get',
      url: `/api/internal/maker_reviews/shop_index?shop_id=${shopId}`,
      headers,
    });
  },
  create(data: Partial<MakerReview>) {
    return api({
      method: 'post',
      url: '/api/internal/maker_reviews',
      headers,
      data,
    });
  },
};
