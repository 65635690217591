<template>
  <div class="flex h-max w-full flex-col items-center bg-gray-50 py-10">
    <div
      class="mb-10 flex w-full max-w-3xl flex-col bg-white p-3 px-5 text-gray-700 md:rounded-lg md:p-10"
    >
      <a
        href="/makers/account?tab=clients"
        class="mb-5 flex items-center text-gray-700 hover:underline"
      >
        <ChevronLeftIcon class="mr-2 size-4 md:size-5" />
        <span class="text-sm md:text-base">
          Volver
        </span>
      </a>
      <div class="mb-5 flex w-full flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
        <div class="flex items-center self-start rounded-md bg-lk-green px-3 py-2 text-white">
          <inline-svg-wrapper
            name="store"
            class="mr-5 size-6 shrink-0 md:size-8"
          />
          <p class="w-full text-left text-base md:text-lg">
            {{ shop.name }}
          </p>
        </div>
        <p class="flex items-center space-x-1 text-sm sm:flex-col sm:items-start sm:space-x-0 md:text-base">
          <span>Fecha de creación:</span>
          <span class="flex items-center font-light">
            <CalendarIcon class="mr-1 size-4 shrink-0" />
            {{ useTimeToDate(shop.createdAt) }}
          </span>
        </p>
      </div>
      <div class="mb-5 flex w-full">
        <div class="md grid w-full grid-cols-2 gap-x-4 break-words text-sm md:gap-x-0 md:text-base">
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Razón social</span>
            <span class="font-light">{{ shop.socialReason }}</span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Rut de la empresa</span>
            <span class="font-light">{{ rutFormat(shop.businessRut) }}</span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Email de contacto</span>
            <span class="flex items-center break-all font-light">
              <EnvelopeIcon class="mr-1 size-4 shrink-0" />
              {{ shop.contactEmail || users[0]?.email }}
            </span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Número de contacto</span>
            <span class="flex items-center font-light">
              <PhoneIcon class="mr-1 size-4 shrink-0" />
              {{ shop.contactNumber || users[0]?.contactNumber }}
            </span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Nombre completo de contacto</span>
            <span class="flex items-center font-light">
              <UserCircleIcon class="mr-1 size-4 shrink-0" />
              {{ shop.contactName || `${users[0]?.firstName} ${users[0]?.lastName}` }}
            </span>
          </p>
          <p class="mb-2 w-full text-left">
            <span class="">¿Tipo de tienda?</span> <br>
            <span class="font-light">{{ $t(`shop.kind.${camelCase(shop.kind)}`) }}</span>
            <span v-if="shop.kind === 'other'"> - {{ shop.otherKind }}</span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Página web</span>
            <a
              v-if="shop.webpageLink"
              class="mr-2 font-light underline"
              :href="useUrlWithHost(shop.webpageLink)"
              target="_blank"
              noopener
            >
              {{ shop.webpageLink }}
            </a>
          </p>
          <p class="mb-2 flex w-full flex-col text-left">
            <span class="">Giro de la empresa</span>
            <span class="font-light">{{ shop.commercialBusiness }}</span>
          </p>
          <p class="mb-2 flex w-full flex-col text-left md:col-span-2">
            <span class="">Direcciones</span>
            <span
              v-for="address in addresses"
              :key="address.id"
              class="font-light"
            >
              {{ address.address }}, {{ address.communeName }}, {{ address.regionName }}
            </span>
          </p>
        </div>
      </div>
      <maker-shop-history
        :maker="maker"
        :shop="shop"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ChevronLeftIcon } from '@heroicons/vue/24/solid';
import { CalendarIcon, PhoneIcon, EnvelopeIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import { rutFormat } from 'rut-helpers';
import { camelCase } from 'lodash';
import useUrlWithHost from './use/url-with-host';
import useTimeToDate from './use/time-to-date';
import makerShopHistory from './maker-shop-history.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';
import useAddressesQuery from './queries/addresses-query';

interface MakerShopProps {
  maker: Maker
  users: User[]
  shop: Shop
}

const props = defineProps<MakerShopProps>();

const addressableId = computed(() => props.shop.id);
const addressableType = computed(() => 'Shop');
const { addresses } = useAddressesQuery(addressableId, addressableType);
</script>
