<template>
  <a
    ref="imageContainer"
    :href="`/makers/${maker.slug}`"
    class="relative flex shrink-0 flex-col items-center justify-center hover:opacity-50"
  >
    <canvas
      v-if="maker.image?.imageBlurhash && showBlurhash"
      ref="blurhashCanvas"
      class="aspect-[3/2] h-32 w-auto shrink-0 rounded-sm bg-cover object-cover sm:h-36 md:h-40 lg:h-44 xl:h-48"
    />
    <div
      :style="{ 'background-image': backgroundImageStyle }"
      class="aspect-[3/2] h-32 w-auto shrink-0 rounded-sm bg-cover bg-center object-cover sm:h-36 md:h-40 lg:h-44 xl:h-48"
    />
    <p
      v-if="showBadge"
      class="absolute right-0 top-0 flex w-full items-center justify-center gap-1 p-1 text-center text-xs font-light text-white md:px-2 md:text-sm"
      :class="{ 'bg-lk-light-green': badgeKind === 'info', 'bg-lk-light-purple': badgeKind === 'discount' }"
    >
      <slot name="icon" />
      <span>{{ badgeText }}</span>
    </p>
    <p class="line-clamp-2 w-full max-w-48 text-left text-sm md:max-w-60 md:text-base lg:max-w-none">
      {{ maker.name }}
    </p>
    <p class="w-full max-w-48 text-left text-xs font-light md:max-w-60 md:text-sm lg:max-w-none">
      min: {{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}
    </p>
  </a>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { decode } from 'blurhash';
import greenKImage from 'assets/images/green-k.jpg';
import useFormatCurrency from './use/format-currency';

const DEFAULT_IMAGE_LENGTH = 100;

interface ShopLandingMakerCardProps {
  maker: Maker;
  showBadge?: boolean;
  badgeText?: string;
  badgeKind?: 'info' | 'discount'
}

const props = withDefaults(defineProps<ShopLandingMakerCardProps>(), {
  showBadge: false,
  badgeText: '',
  badgeKind: 'info',
});

// blurhash
const blurhashCanvas = ref<HTMLCanvasElement | null>(null);
const imageContainer = ref<HTMLDivElement | null>(null);
const backgroundImageStyle = ref('');
const showBlurhash = ref(true);
// eslint-disable-next-line max-statements, complexity
function renderBlurhash() {
  if (blurhashCanvas.value && props.maker.image?.imageBlurhash) {
    const ctx = blurhashCanvas.value.getContext('2d');
    const makerBlurhash = props.maker.image.imageBlurhash;

    if (ctx && !!makerBlurhash && imageContainer.value?.getBoundingClientRect()) {
      let { width, height } = imageContainer.value?.getBoundingClientRect();

      // Set default width and height if they're invalid (0 or undefined)
      width = width || DEFAULT_IMAGE_LENGTH;
      height = height || DEFAULT_IMAGE_LENGTH;

      blurhashCanvas.value.width = width;
      blurhashCanvas.value.height = height;

      // Decode the Blurhash into pixel data
      const pixels = decode(makerBlurhash, width, height);

      // Create image data with the same dimensions
      const imageData = ctx.createImageData(width, height);

      // Ensure the pixel data fits correctly before setting
      if (pixels.length === imageData.data.length) {
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);
      } else {
        console.error('Pixel data size mismatch! Cannot apply Blurhash.');
      }
    }
  }
}

function preloadImage(url: string) {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    showBlurhash.value = false; // Hide the blurhash once the image is loaded
    backgroundImageStyle.value = `url(${url})`;
  };
}

onMounted(() => {
  const imageUrl = props.maker.image?.derivatives?.jpgMd?.url || greenKImage;
  renderBlurhash();
  preloadImage(imageUrl);
});
</script>

