<template>
  <TransitionRoot
    :show="modelValue"
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <Dialog
      :open="modelValue"
      as="div"
      class="relative z-50 text-gray-700"
      @close="emit('update:modelValue', false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
      >
        <div
          class="fixed inset-0 bg-black/30"
          aria-hidden="true"
        />
      </TransitionChild>
      <div class="fixed inset-0 flex items-center justify-center p-2 md:px-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
        >
          <DialogPanel class="flex h-[420px] max-h-[40rem] w-full max-w-lg flex-col overflow-y-scroll rounded-md bg-white p-3 md:h-[500px] md:p-5">
            <button
              class="ml-auto focus:outline-none"
              @click="emit('update:modelValue', false)"
            >
              <XMarkIcon class="size-5 fill-gray-700 md:size-6" />
            </button>
            <h2 class="font-serif text-xl md:text-2xl">
              Subir factura pedido #{{ props.subOrder.id }}
            </h2>
            <form
              class="flex size-full flex-col"
              @submit="onSubmit"
            >
              <div
                class="mt-2 flex w-full flex-col text-sm md:mt-3 md:text-base"
                :class="{ 'hover:bg-gray-50': !showTransferPaymentInformation }"
              >
                <button
                  class="flex w-full items-center"
                  :class="{ 'mb-3': showTransferPaymentInformation }"
                  type="button"
                  @click="showTransferPaymentInformation = !showTransferPaymentInformation"
                >
                  <span>Mostrar datos de faturación</span>
                  <ChevronDownIcon
                    class="ml-2 size-3 md:size-4"
                    :class="{ 'rotate-180 duration-150': showTransferPaymentInformation,
                              'duration-150': !showTransferPaymentInformation }"
                  />
                </button>
                <lokal-transfer-payment-information v-if="showTransferPaymentInformation" />
              </div>
              <p class="mt-1 text-sm md:mt-2 md:text-base">
                <span>La factura debe ser emitida a nombre de Lokal Spa.</span>
              </p>
              <button
                class="mt-1 flex items-center gap-2 hover:bg-gray-50 md:mt-2 md:text-lg"
                type="button"
                @click="showTotalsDetails = !showTotalsDetails"
              >
                <span>Monto neto a facturar: {{ useFormatCurrency(amountToBill) }}</span>
                <ChevronDownIcon
                  class="size-4 fill-gray-700 transition-transform duration-150"
                  :class="{ 'rotate-180': showTotalsDetails }"
                />
              </button>
              <p
                v-if="showTotalsDetails"
                class="ml-2 text-sm font-light md:text-base"
              >
                Neto total: {{ useFormatCurrency(subOrder.totalWholesaleAmountCents) }} <br>
                Descuento total productos: {{ useFormatCurrency(subOrder.totalMakerDiscountAmountCents) }} <br>
                Descuento por volumen: {{ useFormatCurrency(subOrder.volumeDiscountAmountCents) }} <br>
                Descuento por código: {{ useFormatCurrency(subOrder.makerDiscountCodeAmountCents) }} <br>
                Comisión Lokal: {{ useFormatCurrency(totalCommission) }} <br>
              </p>
              <lokal-loading v-if="subOrderPurchaseInvoicesIsLoading" />
              <template v-else>
                <p
                  class="mt-2 md:mt-3 md:text-lg"
                  :class="{ 'line-through': showMakerInvoices }"
                >
                  Facturas detectadas al pedido
                </p>
                <div
                  class="mt-1 grid grid-cols-4 items-center border text-center text-sm font-light md:mt-2 md:text-base"
                >
                  <p class="border-b py-0.5 md:py-1">
                    Nro. folio
                  </p>
                  <p class="border-b py-0.5 md:py-1">
                    Costo envío
                  </p>
                  <p class="border-b py-0.5 md:py-1">
                    Neto
                  </p>
                  <p class="border-b py-0.5 md:py-1">
                    Factura
                  </p>
                  <template
                    v-for="purchaseInvoice in subOrderPurchaseInvoices"
                    :key="`purchase-invoice-${purchaseInvoice.id}`"
                  >
                    <p :class="{ 'line-through': showMakerInvoices }">
                      {{ purchaseInvoice.number }}
                    </p>
                    <p :class="{ 'line-through': showMakerInvoices }">
                      {{ useFormatCurrency(purchaseInvoice.shippingCost) }}
                    </p>
                    <p :class="{ 'line-through': showMakerInvoices }">
                      {{ useFormatCurrency(purchaseInvoice.netAmount) }}
                    </p>
                    <a
                      :href="purchaseInvoice.urlPdf"
                      target="_blank"
                      class="hover:text-blue-700 hover:underline"
                      :class="{ 'line-through': showMakerInvoices }"
                    >
                      Ver factura
                    </a>
                    <Field
                      v-if="!showMakerInvoices"
                      v-model="purchaseInvoice.id"
                      :name="`field-purchaseInvoice-${purchaseInvoice.id}`"
                      hidden
                    />
                  </template>
                </div>
                <p
                  v-if="subOrderPurchaseInvoices?.length > 0"
                  class="mt-1 text-sm md:mt-2 md:text-base"
                  :class="{ 'line-through': showMakerInvoices }"
                >
                  Hemos detectado la factura #{{ subOrderPurchaseInvoices?.[0]?.number }} para este pedido.
                  Los montos tienen una diferencia menor a {{ netAmountDifference * 100 }}%.
                </p>
              </template>
              <template v-if="showMakerInvoices">
                <div class="mt-2 flex w-full items-center gap-1 md:mt-3">
                  <p>Nuevos folios:</p>
                  <button
                    v-for="invoiceNumber in otherPurchaseInvoiceNumbers"
                    :key="`another-purchase-invoice-${invoiceNumber}`"
                    class="flex items-center gap-1 rounded-full bg-lk-green px-2 py-0.5 text-xs text-white md:text-sm"
                    type="button"
                  >
                    <span>#{{ invoiceNumber }}</span>
                    <XMarkIcon
                      class="size-2 fill-white md:size-3"
                      @click="removeAnotherPurchaseInvoice(invoiceNumber)"
                    />
                  </button>
                </div>
                <Field
                  v-model="otherPurchaseInvoiceNumbers"
                  name="otherPurchaseInvoiceNumbers"
                  label="Nuevos folios"
                  hidden
                  :rules="{ required: true }"
                />
                <div class="mt-1 flex w-full items-center rounded-md border border-lk-light-gray text-sm focus-within:border-lk-green focus-within:outline-none md:text-base">
                  <Field
                    v-model.number="otherPurchaseInvoiceNumber"
                    type="number"
                    placeholder="Número de factura"
                    name="otherPurchaseInvoiceNumber"
                    class="w-full flex-1 rounded-md p-1.5 focus:outline-none"
                    @keyup.enter.stop="tryToAddAnotherPurchaseInvoice"
                  />
                  <button
                    v-if="otherPurchaseInvoiceNumber"
                    class="mr-2 flex items-center gap-1 rounded-md border p-1 text-xs md:text-sm"
                    type="button"
                    @click="addAnotherPurchaseInvoice(otherPurchaseInvoiceNumber)"
                  >
                    <PlusIcon class="size-2 fill-gray-700 md:size-3" />
                    <span>Agregar</span>
                  </button>
                </div>
                <ErrorMessage
                  name="otherPurchaseInvoiceNumbers"
                  class="text-xs text-red-500 md:text-sm"
                />
              </template>
              <div
                class="flex w-full flex-col"
                :class="{ 'mt-2': showMakerInvoices, 'mt-auto': !showMakerInvoices }"
              >
                {{ purchaseInvoiceConciliationsBulkCreateIsLoading }}
                <lokal-loading
                  v-if="purchaseInvoiceConciliationsBulkCreateIsLoading"
                  :loading="purchaseInvoiceConciliationsBulkCreateIsLoading"
                />
                <template v-else>
                  <button
                    class="mt-auto rounded-md border border-lk-green p-1.5 text-sm text-lk-green md:text-base"
                    :class="{ 'cursor-not-allowed': Object.keys(errors).length > 0,
                              'hover:bg-lk-green hover:text-white': Object.keys(errors).length === 0 }"
                    type="submit"
                    :disabled="Object.keys(errors).length > 0"
                  >
                    <span v-if="showMakerInvoices">
                      Confirmar folios seleccionados:
                      {{ otherPurchaseInvoiceNumbers.map((number) => `#${number}`).join(', ') }}
                    </span>
                    <span v-else>
                      Confirmar folios detectados:
                      {{ subOrderPurchaseInvoices?.map((invoice) => `#${invoice.number}`).join(', ') }}
                    </span>
                  </button>
                  <button
                    v-if="showMakerInvoices"
                    class="mt-1 text-center text-sm hover:underline md:mt-2 md:text-base"
                    @click="showMakerInvoices = !showMakerInvoices"
                  >
                    ¿Es la factura detectada?
                  </button>
                  <button
                    v-else
                    class="mt-1 text-center text-sm hover:underline md:mt-2 md:text-base"
                    @click="showMakerInvoices = !showMakerInvoices"
                  >
                    ¿Es otra factura?
                  </button>
                </template>
              </div>
            </form>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
import { XMarkIcon, ChevronDownIcon, PlusIcon } from '@heroicons/vue/24/solid';
import { ref, computed, watch } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import purchaseInvoicesApi from 'api/purchase-invoices';
import purchaseInvoiceConciliationsApi from 'api/purchase-invoice-conciliations';
import { round } from 'lodash';
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import useFormatCurrency from './use/format-currency';
import useBillingTools from './use/billing-tools';
import lokalTransferPaymentInformation from './shared/lokal-transfer-payment-information.vue';

const ROUND_BY = 3;

interface UploadBillDialogProps {
  modelValue: boolean
  subOrderId: number
  subOrder: SubOrder
  maker: Maker
}

const props = withDefaults(defineProps<UploadBillDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'invoice-created'): void, (e: 'update:subOrder'): void
}>();

const showTotalsDetails = ref(false);
const showMakerInvoices = ref(false);
const showTransferPaymentInformation = ref(false);
const selectedPurchaseInvoices = ref<PurchaseInvoice[]>([]);
const otherPurchaseInvoiceNumber = ref<number | null>(null);
const otherPurchaseInvoiceNumbers = ref<number[]>([]);

const { billTools } = useBillingTools();

const commissionFraction = computed(() => props.subOrder.commission / billTools.value.totalPorcentage);
const makerDiscountedAmount = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.totalMakerDiscountAmountCents);
const makerDiscountedAmountWithoutVolume = computed(() =>
  makerDiscountedAmount.value - props.subOrder.volumeDiscountAmountCents - props.subOrder.makerDiscountCodeAmountCents);
const totalCommission = computed(() => makerDiscountedAmountWithoutVolume.value * commissionFraction.value);
const amountToBill = computed(() => makerDiscountedAmountWithoutVolume.value - totalCommission.value);

const subOrderPurchaseInvoicesQuery = useQuery(
  ['sub-order-purchase-invoices', props.maker.id, props.subOrder.id],
  () => purchaseInvoicesApi.index(props.maker.id, `q[sub_order_id_eq]=${props.subOrder.id}`),
);
const subOrderPurchaseInvoicesIsLoading = computed(() => subOrderPurchaseInvoicesQuery.isLoading.value);
const subOrderPurchaseInvoices = computed(() =>
  subOrderPurchaseInvoicesQuery.data.value?.data?.purchaseInvoices as PurchaseInvoice[]);
const subOrderPurchaseInvoicesTotalNetAmount = computed(() =>
  subOrderPurchaseInvoices.value?.reduce((acc, invoice) => acc + invoice.netAmount - invoice.shippingCost, 0));

const netAmountDifference = computed(() =>
  round(
    Math.abs(amountToBill.value - subOrderPurchaseInvoicesTotalNetAmount.value) / amountToBill.value,
    ROUND_BY,
  ),
);

const bulkCreateParams = computed(() => {
  if (showMakerInvoices.value) {
    return otherPurchaseInvoiceNumbers.value?.map((invoiceNumber) => ({
      makerId: props.maker.id,
      subOrderId: props.subOrder.id,
      purchaseInvoiceNumber: invoiceNumber,
    }));
  }

  return subOrderPurchaseInvoices.value?.map((invoice) => ({
    makerId: props.maker.id,
    subOrderId: props.subOrder.id,
    purchaseInvoiceNumber: invoice.number,
  }));
});
const purchaseInvoiceConciliationsBulkCreateMutation = useMutation(
  () => purchaseInvoiceConciliationsApi.bulkCreate(bulkCreateParams.value),
  {
    onSuccess: () => {
      emit('invoice-created');
      emit('update:modelValue', false);
    },
  },
);
const purchaseInvoiceConciliationsBulkCreateIsLoading = computed(() =>
  purchaseInvoiceConciliationsBulkCreateMutation.isLoading.value);
const { handleSubmit, validate, errors } = useForm();

const onSubmit = handleSubmit(() => {
  purchaseInvoiceConciliationsBulkCreateMutation.mutate();
});

watch(showMakerInvoices, () => {
  selectedPurchaseInvoices.value = [];
  otherPurchaseInvoiceNumbers.value = [];
});

async function addAnotherPurchaseInvoice(invoiceNumber: number) {
  if (otherPurchaseInvoiceNumbers.value.includes(invoiceNumber)) return;

  otherPurchaseInvoiceNumbers.value.push(invoiceNumber);
  otherPurchaseInvoiceNumber.value = null;
  await validate();
}

function tryToAddAnotherPurchaseInvoice() {
  if (otherPurchaseInvoiceNumber.value) {
    addAnotherPurchaseInvoice(otherPurchaseInvoiceNumber.value);
  }
}

function removeAnotherPurchaseInvoice(invoiceNumber: number) {
  otherPurchaseInvoiceNumbers.value = otherPurchaseInvoiceNumbers.value.filter((number) =>
    number !== invoiceNumber,
  );
}
</script>
