<template>
  <div class="mb-10 flex size-full flex-col items-center text-gray-700 md:max-w-7xl md:px-5 lg:px-10">
    <div class="mb-3 flex w-full flex-col px-2 md:px-0">
      <p class="mb-1 flex flex-col font-serif text-xl md:text-2xl lg:text-3xl">
        Ordenes pendientes de pago
      </p>
      <div class="flex items-center">
        <p class="mr-1 text-base text-red-500 md:text-lg">
          Deuda total (vencida): {{ useFormatCurrency(totalAmountDebt) }}
        </p>
        <button
          class="ml-auto flex shrink-0 items-center justify-center rounded-md bg-lk-green p-1.5 text-sm text-white hover:bg-lk-green-deep md:p-2 md:text-base"
          @click="showPaymentInformationDialog = !showPaymentInformationDialog"
        >
          <PlusIcon class="mr-1 size-4 shrink-0 md:mr-2" />
          <span>Agregar pago</span>
        </button>
      </div>
    </div>
    <lokal-loading
      v-if="ordersQuery.isLoading.value"
      :loading="ordersQuery.isLoading.value"
    />
    <div
      v-else
      class="flex w-full flex-col rounded-md bg-white px-2 py-3 md:px-4 md:py-5 lg:px-10"
    >
      <div class="w-full overflow-x-auto">
        <table class="mb-6 rounded-md border border-gray-300 text-sm sm:w-full md:mb-8 md:text-base">
          <tr
            class="border-b border-gray-300 text-center"
          >
            <th class="shrink-0 p-1">
              Orden id
            </th>
            <th class="shrink-0 p-1">
              Nro. Factura
            </th>
            <th class="shrink-0 p-1">
              Monto total
            </th>
            <th>
              Fecha vencimiento
            </th>
            <th>
              Mora (días)
            </th>
            <th class="shrink-0 p-1">
              Abonado
            </th>
            <th class="shrink-0 p-1">
              Por pagar
            </th>
          </tr>
          <shop-unpaid-order-card
            v-for="order in orders"
            :key="order.id"
            class="text-center"
            :shop-id="shopId"
            :order="order"
            @total-amount-debt="(totalAmountDebt: number) => updateTotalAmountDebtByOrder(order.id, totalAmountDebt)"
          />
        </table>
      </div>
      <p class="mb-2 text-sm md:text-base">
        Recuerda que puedes abonar tus ordenes en cualquier momento. Algunos clientes prefieren
        pagar todas las semanas, otros prefieren pagar una vez al mes o al finalizar el plazo de la
        orden. Tú decides. Nosotros te apoyamos.
      </p>
      <p class="text-sm md:text-base">
        Recuerda también que los pagos anticipados <b>mejoran</b> tu historial crediticio y te permiten
        postular a un aumento de crédito.
      </p>
    </div>
    <lokal-payment-information-dialog
      v-if="showPaymentInformationDialog"
      v-model="showPaymentInformationDialog"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import useOrdersQuery from './queries/orders-query';
import shopUnpaidOrderCard from './shop-unpaid-order-card.vue';
import lokalPaymentInformationDialog from './shared/lokal-payment-information-dialog.vue';
import useFormatCurrency from './use/format-currency';
import useUrlMethods from './use/url-methods';

const PAYMENT_STATUS_ENUM_VALUE = 2;

const props = defineProps<{
  shopId: number
}>();

const computedShopId = computed(() => props.shopId);
const currentPage = ref(1);
const queryParams = computed(() =>
  `q[payment_status_not_eq]=${PAYMENT_STATUS_ENUM_VALUE}&q[s]=payment_date asc, created_at asc`);
const { orders, ordersQuery } = useOrdersQuery(computedShopId, currentPage, queryParams);
const totalAmountDebtByOrder = ref({} as Record<number, number>);
const totalAmountDebt = computed(() =>
  Object.values(totalAmountDebtByOrder.value).reduce((acc, curr) => acc + curr, 0));

function updateTotalAmountDebtByOrder(orderId: number, amountDebt: number) {
  totalAmountDebtByOrder.value = {
    ...totalAmountDebtByOrder.value,
    [orderId]: amountDebt,
  };
}

const { getQueryParam } = useUrlMethods();
const showPaymentDialog = computed(() => getQueryParam('showPaymentDialog') === 'true');
const showPaymentInformationDialog = ref(showPaymentDialog.value);

watch(showPaymentDialog, (newValue) => {
  if (newValue) {
    showPaymentInformationDialog.value = true;
  }
});
</script>
