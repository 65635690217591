<template>
  <div class="mb-10 flex size-full flex-col items-center md:max-w-7xl md:px-5 lg:px-10">
    <div class="mb-3 flex w-full items-center px-2 md:px-0">
      <p class="flex flex-col self-end font-serif text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Usuarios
      </p>
    </div>
    <div class="flex size-full flex-col rounded-md bg-white px-2 py-3 md:px-4 md:py-5 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <div
          v-for="user, index in users"
          :key="index"
          class="mb-3 flex flex-col items-center rounded-md bg-gray-50 p-5 md:flex-row"
        >
          <UserCircleIcon class="size-10 fill-lk-green md:mr-5" />
          <p class="flex flex-col items-center md:items-start">
            <span class="mb-1 font-semibold">{{ user.firstName }} {{ user.lastName }}</span>
            <span class="text-sm text-gray-500">{{ user.email }}</span>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import usersApi from 'api/users';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps<{
  shopId: number
}>();

const { isLoading, data } = useQuery('users', () => usersApi.index(`shop_id=${props.shopId}`));
const users = computed(() => data.value?.data.users as User[]);
</script>
