<template>
  <div class="mb-10 flex size-full flex-col items-center md:max-w-7xl md:px-5 lg:px-10">
    <div class="mb-3 flex w-full items-center px-2 md:px-0">
      <p class="flex flex-col self-end font-serif text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Invita a marcas y obtén créditos
      </p>
    </div>
    <div class="flex size-full flex-col rounded-md bg-white px-2 py-3 md:px-4 md:py-5 lg:px-10">
      <div class="mb-2 flex w-full flex-col md:mb-5">
        <p class="mb-2 text-sm font-light md:text-base">
          Invita a marcas a registrarse con tu link de referidos y obten
          <span class="font-semibold">$10.000</span> en créditos por cada marca que entre a Lokal.
        </p>
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          class="self-center"
        />
        <copy-text
          :text="recomendationLink"
          class="mb-5"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';
import copyText from './shared/copy-text.vue';

const props = defineProps<{
  shopId: number
}>();

const protocol = computed(() => (process.env.APPLICATION_HOST?.includes('http') ? '' : 'https://'));
const host = ref(`${protocol.value}${process.env.APPLICATION_HOST}`);
const shopQuery = useQuery(['shop', props.shopId], () => shopsApi.get(props.shopId), {
  enabled: computed(() => !!props.shopId),
});
const isLoading = computed(() => shopQuery.isLoading.value);
const shop = computed(() => shopQuery.data.value?.data.shop as Shop);

const recomendationLink = computed(() =>
  (`${host.value}/potential_maker?reference=${shop.value?.slug}`));
</script>
