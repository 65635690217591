<template>
  <div class="relative flex w-full flex-col items-center text-gray-700">
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :show-dialog="message[0].includes('dialog')"
        :color-key="(message[0] as ColorKeyType)"
        :message="message[1]"
        title="Información importante"
      />
    </template>
    <product-list-aside-filters
      v-if="menuStore.showProductFilterSidebar"
      :cross-enabled="true"
      :maker-slug="maker.slug"
      :with-maker-filter="false"
      class="fixed left-0 z-20 h-screen w-3/5 overflow-y-auto border-r border-gray-300 bg-gray-50 text-sm text-gray-700 md:h-auto md:w-80 md:text-base"
      :class="{ 'border-r border-gray-300': isMobile }"
    />
    <div class="flex w-full flex-col items-center py-6 md:py-10">
      <div class="flex w-full max-w-6xl flex-col px-4 md:px-10 2xl:px-0">
        <div class="mb-3 flex items-end">
          <a
            href="/makers"
            class="hover:underline"
          >
            Marcas
          </a>
          <span class="mx-1">/</span>
          <span class="font-light capitalize">{{ maker.name }}</span>
        </div>
        <div
          :style="{ 'background-image': `url(${coverImageUrl})` }"
          class="mb-2 flex h-40 w-full flex-col justify-end rounded-md bg-cover bg-center object-cover p-2 md:h-60 md:p-4"
        >
          <button
            class="flex items-center self-end rounded-md bg-white p-2 px-4 text-base text-gray-700 hover:bg-gray-700 hover:text-white md:text-lg"
            @click="manageShowSendMessageDialog"
          >
            <span

              class="mr-2 hidden md:inline-block"
            >Enviar mensaje</span>
            <ChatBubbleLeftRightIcon class="size-5" />
          </button>
        </div>
        <img
          class="-mt-14 mb-4 ml-5 size-20 rounded-full object-cover sm:ml-10 md:size-28"
          :src="maker.logoImage?.derivatives['jpgMd']?.url || greenKImage"
        >
        <div class="flex w-full flex-col">
          <h2 class="mb-4 font-serif text-2xl font-medium text-lk-green md:text-3xl">
            {{ maker.name }}
          </h2>
          <p class="mb-2 text-base md:text-lg">
            Pedidos desde
            <span
              v-if="sessionStore.user?.id || !makerDestinationsQueryIsLoading"
              class="font-semibold"
            >
              <template v-if="currentMakerDestination">
                {{ useFormatCurrency(currentMakerDestination.minimumPurchaseAmountCents) }}
              </template>
              <template v-else>
                {{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}
              </template>
              <span> + IVA</span>
            </span>
            <span
              v-else
              class="blur"
            >
              fake value
            </span>
          </p>
          <p class="mb-2 flex flex-col text-sm md:flex-row md:items-center md:space-x-1 md:text-base">
            <lokal-loading
              v-if="addressesAreLoading"
              :loading="addressesAreLoading"
              size="xs"
            />
            <span
              v-else
              class="mb-1 shrink-0 text-sm md:mb-0 md:text-base"
            >
              Despachos desde {{ addresses?.[0]?.communeName }}, {{ addresses?.[0]?.regionName }}
            </span>
            <span class="hidden md:inline-block">·</span>
            <template v-if="sessionStore.user?.id">
              <lokal-loading
                v-if="destinationAddressLoading"
                :loading="destinationAddressLoading"
                size="xs"
              />
              <maker-shipping-price
                v-else-if="destinationAddress?.regionName && destinationAddress?.communeName"
                :maker-id="maker.id"
                :destination-address="destinationAddress"
                :carrier-codes="carrierCodes"
                :in-checkout="false"
              >
                <template #shipping-message="{ validMakerDestination }">
                  <span v-if="cartStore.shippingCostConfirmAfter[maker.id]">
                    Costo de despacho sujeto a cotización
                  </span>
                  <span v-else-if="validMakerDestination">Costo estimado de despacho desde:</span>
                  <span v-else>Esta marca no despacha a tu dirección actual.</span>
                </template>
              </maker-shipping-price>
            </template>
          </p>
          <div class="mb-3 flex w-auto items-center text-sm text-gray-700 md:text-base">
            <div
              :class="{ 'w-auto': showCompleteDescription, 'truncate': !showCompleteDescription }"
              v-html="DOMPurify.sanitize(truncatedDescription, { USE_PROFILES: { html: true }})"
            />
            <button
              class="flex shrink-0 items-center md:ml-3"
              :class="{ 'self-center': !showCompleteDescription, 'self-start': showCompleteDescription }"
              @click="showCompleteDescription = !showCompleteDescription"
            >
              <span class="mr-2 hidden underline md:inline-block">{{ showCompleteDescription ? 'Ver menos' : 'Ver más' }}</span>
              <ChevronDownIcon
                class="size-5 shrink-0"
                :class="{ 'rotate-180': showCompleteDescription }"
              />
            </button>
          </div>
          <p class="mb-3 text-sm md:text-base">
            Tiempo promedio de despacho es de
            <span class="font-semibold">{{ maker.averageDeliveryDays }}</span>
            días hábiles desde la fecha de confirmación.
          </p>
        </div>
        <div class="mb-3 flex w-full flex-col md:flex-row md:items-center">
          <button
            v-if="maker.samplesEnabled"
            class="mb-1 mr-0 rounded-md border border-gray-700 p-1.5 text-left text-sm hover:bg-gray-700 hover:text-white md:mb-0 md:mr-3 md:p-2 md:text-base"
            @click="showSampleRequestDialog = !showSampleRequestDialog"
          >
            ¿Te gustaría solicitar una muestra?
          </button>
          <button
            class="rounded-md border border-gray-700 p-1.5 text-left text-sm hover:bg-gray-700 hover:text-white md:p-2 md:text-base"
            @click="showMissingProductsRequestDialog = !showMissingProductsRequestDialog"
          >
            ¿Faltan productos?
          </button>
        </div>
        <div class="relative mb-4 flex w-full snap-x snap-proximity flex-col gap-2 overflow-x-auto pb-5 sm:mb-6 sm:flex-row md:gap-3">
          <maker-show-shippings
            class="w-full shrink-0 snap-center sm:w-1/2"
            :maker="maker"
          />
          <maker-show-discounts
            :maker="maker"
            class="w-full shrink-0 snap-center sm:w-1/2 lg:w-2/5"
          />
        </div>
        <product-list-display
          :maker-slug="maker.slug"
          :maker-query-param="`q[maker_id_eq]=${maker.id}&q[s][]=status+desc&q[s][]=maker_priority+asc&`"
          :maker="maker"
          :total-product-count="totalProductCount"
        />
      </div>
    </div>
    <maker-show-missing-products-request-dialog
      v-if="showMissingProductsRequestDialog"
      v-model="showMissingProductsRequestDialog"
      :maker="maker"
    />
    <maker-show-sample-request-dialog
      v-if="showSampleRequestDialog"
      v-model="showSampleRequestDialog"
      :maker="maker"
    />
    <maker-show-send-message-dialog
      v-if="showSendMessageDialog"
      v-model="showSendMessageDialog"
      :maker="maker"
    />
    <lokal-user-register-dialog
      v-if="showRegisterDialog"
      v-model="showRegisterDialog"
      :initial-value-sign-in="false"
    />
    <div
      v-show="showFreeShippingBanner"
      class="fixed bottom-0 z-20 flex w-full bg-yellow-50 p-2 text-amber-900 md:p-3"
    >
      <bottom-message-list-card
        :maker-id="maker.id"
        class="w-full"
        @maker-enabled="(value: boolean) => showFreeShippingBanner = value"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import DOMPurify from 'isomorphic-dompurify';
import { ChatBubbleLeftRightIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import useSessionStore from 'stores/user-store';
import useGeneralMenuStore from 'stores/general-menu-store';
import useCartStore from 'stores/cart';
import greenKImage from 'assets/images/green-k.jpg';
import ProductListDisplay from './product-list-display.vue';
import useFormatCurrency from './use/format-currency';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import makerShowDiscounts from './maker-show-discounts.vue';
import makerShowSendMessageDialog from './maker-show-send-message-dialog.vue';
import makerShowShippings from './maker-show-shippings.vue';
import lokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import MakerShowSampleRequestDialog from './maker-show-sample-request-dialog.vue';
import MakerShowMissingProductsRequestDialog from './maker-show-missing-products-request-dialog.vue';
import MakerShippingPrice from './shared/maker-shipping-price.vue';
import useCarriersQuery from './queries/carriers-query';
import useAddressesQuery from './queries/addresses-query';
import useShopQuery from './queries/shop-query';
import useCommunesQuery from './queries/communes-query';
import useRegionsQuery from './queries/regions-query';
import useMakerDestinationsQuery from './queries/maker-destinations-query';
import productListAsideFilters from './product-list-aside-filters.vue';
import bottomMessageListCard from './bottom-message-list-card.vue';

const MD_CHARACTERS_TO_SHOW = 100;
const CHARACTERS_TO_SHOW = 50;

interface MakerShowProps {
  maker: Maker,
  totalProductCount: number,
  messages?: string[][]
}

const props = withDefaults(defineProps<MakerShowProps>(), {
  messages: () => [['', '']],
});

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile');
const sessionStore = useSessionStore();
const cartStore = useCartStore();

const showFreeShippingBanner = ref(false);
const showRegisterDialog = ref(false);
const showSendMessageDialog = ref(false);
const showSampleRequestDialog = ref(false);
const showMissingProductsRequestDialog = ref(false);
function manageShowSendMessageDialog() {
  if (sessionStore.user?.id) {
    showSendMessageDialog.value = true;
  } else {
    showRegisterDialog.value = true;
  }
}

const showCompleteDescription = ref(false);
const charactersToShow = computed(() => (isMobile.value ? CHARACTERS_TO_SHOW : MD_CHARACTERS_TO_SHOW));
const truncatedDescription = computed(() => {
  if (props.maker.description.length > charactersToShow.value && !showCompleteDescription.value) {
    return `${props.maker.description.slice(0, charactersToShow.value)} ...`;
  }

  return props.maker.description;
});
const coverImageUrl = computed(() =>
  props.maker.coverImage?.derivatives?.jpgXl?.url || greenKImage);

const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});

const { addresses, addressesQuery } = useAddressesQuery(computed(() => props.maker.id), computed(() => 'Maker'));
const addressesAreLoading = computed(() => addressesQuery.isLoading.value);

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);
const carrierCodes = computed(() => carriers.value?.map((carrier) => carrier.code));

const currentAddress = computed(() => sessionStore.currentAddress);

const shopQueryEnabled = computed(() => !!sessionStore.user?.shopId && !currentAddress.value?.id);
const { shop, shopQuery } = useShopQuery(computed(() => sessionStore.user?.shopId), shopQueryEnabled);
const { communes, communesQuery } = useCommunesQuery(computed(() => ''), shopQueryEnabled);
const { regions, regionsQuery } = useRegionsQuery(shopQueryEnabled);
const destinationAddressLoading = computed(() =>
  carriersQuery.isLoading.value || shopQuery.isLoading.value || communesQuery.isLoading.value ||
  regionsQuery.isLoading.value);

function constructPartialAddress(commune: Commune, region: Region) {
  if (!commune || !region) return {} as Address;

  return {
    addressableType: 'Shop',
    addressableId: shop.value?.id,
    communeId: commune.id,
    regionId: region.id,
    communeName: commune.name,
    regionName: region.name,
  };
}

const destinationAddress = computed(() => {
  if (currentAddress.value?.id) return currentAddress.value;
  if (shop.value?.referenceCommuneId && shop.value?.referenceRegionId) {
    const internalCommune = communes.value?.find((commune) => commune.id === shop.value?.referenceCommuneId);
    const internalRegion = regions.value?.find((region) => region.id === shop.value?.referenceRegionId);

    return constructPartialAddress(internalCommune as Commune, internalRegion as Region);
  }

  return {} as Address;
});

const {
  currentMakerDestination,
  makerDestinationsQueryIsLoading,
} = useMakerDestinationsQuery(computed(() => props.maker.id));

</script>

