<template>
  <a
    class="relative flex flex-col items-center rounded-md"
    :href="`/makers/${maker.slug}`"
  >
    <div
      ref="imageContainer"
      class="relative flex h-36 w-full shrink-0 flex-col items-center overflow-hidden rounded-t-md sm:h-40 md:h-44 lg:h-48 xl:h-52"
    >
      <canvas
        v-if="maker.image?.imageBlurhash && showBlurhash"
        ref="blurhashCanvas"
        class="size-full bg-cover"
      />
      <div
        :style="{ 'background-image': backgroundImageStyle }"
        class="absolute size-full bg-cover bg-center object-cover duration-300 ease-in-out hover:scale-105"
      />
      <template v-if="!showBlurhash">
        <p
          v-if="makerCreatedInTheLastMonth"
          class="z-10 w-full self-start rounded-t-md bg-lk-light-green p-1 text-center text-xs text-white md:p-2 md:text-sm"
        >
          Nueva
        </p>
        <p
          v-if="maker.activeDiscounts?.length"
          class="z-10 flex w-full items-center justify-center self-end bg-lk-light-purple p-1 text-xs text-white md:text-sm"
          :class="{ 'rounded-t-md': !makerCreatedInTheLastMonth }"
        >
          {{ maker.activeDiscounts[0].name }}: -{{ maker.activeDiscounts[0].totalDiscount }}%
        </p>
      </template>
    </div>
    <div class="flex w-full flex-col rounded-b-md bg-white text-gray-700">
      <p class="flex w-full flex-col justify-center text-sm md:text-base">
        <span class="line-clamp-2">{{ maker.name }}</span>
        <span
          v-if="sessionStore.user?.id"
          class="text-xs font-light md:text-sm"
        >
          Mínimo: {{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}
        </span>
        <span
          v-else
          class="text-xs font-light md:text-sm"
        >
          Mínimo: <span class="blur">fake value</span>
        </span>
      </p>
      <lokal-tooltip
        v-if="(!isLoading && includesCurrentAddress) || subsidizedShippings.length > 0"
        :information="shippingInformation"
        button-classes="flex items-center space-x-1"
        information-classes="w-60 p-1 md:p-2 text-xs md:text-sm"
        :position-options="(isMobile && !oddPosition) ? 'right' : 'left'"
      >
        <template #custom-content>
          <TruckIcon class="h-3 text-gray-700 md:h-4" />
          <span class="text-left text-xs font-light md:text-sm">{{ shippingShortMessage }}</span>
        </template>
      </lokal-tooltip>
    </div>
  </a>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue';
import { TruckIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import { orderBy } from 'lodash';
import { decode } from 'blurhash';
import greenKImage from 'assets/images/green-k.jpg';
import useFormatCurrency from './use/format-currency';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useFreeShippingsQuery from './queries/free-shippings-query';

const DEFAULT_IMAGE_LENGTH = 100;
const DOUBLE = 2;

const props = withDefaults(defineProps<{
  maker: Maker,
  oddPosition?: boolean,
}>(), {
  oddPosition: false,
});

const sessionStore = useSessionStore();

const isMobile = inject('isMobile');
const makerCreatedInTheLastMonth = computed(() => {
  const today = new Date();
  const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
  const makerCreatedAt = new Date(props.maker.createdAt);

  return makerCreatedAt >= oneMonthAgo;
});

const subsidizedShippings = computed(() => props.maker.subsidizedShippings || []);

const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { freeShippings, freeShippingsQuery } = useFreeShippingsQuery(
  computed(() => props.maker.id), freeShippingsQueryParams);
const isLoading = computed(() => freeShippingsQuery.isLoading.value);

const includesCurrentAddress = computed(() => {
  let includesAddress = false;
  freeShippings.value?.forEach(freeShipping => {
    freeShipping.regions.forEach(region => {
      if (region.id === sessionStore.currentRegionId) includesAddress = true;
    });
    freeShipping.communes.forEach(commune => {
      if (commune.id === sessionStore.currentCommuneId) includesAddress = true;
    });
  });

  return includesAddress;
});

const shippingShortMessage = computed(() => {
  if (includesCurrentAddress.value) return 'Despacho gratis';
  else if (props.maker?.subsidizedShippings && props.maker?.subsidizedShippings.length > 0) {
    return 'Despacho con descuento';
  }

  return '';
});

const shippingInformation = computed(() => {
  if (!freeShippings.value?.length && !subsidizedShippings.value?.length) return '';

  const baseText = orderBy(freeShippings.value, 'minimumAmountCents', 'asc').map((freeShipping) => {
    const minimumPurchaseAmount = useFormatCurrency(freeShipping.minimumAmountCents);
    const regionDestinationIds = freeShipping.regions.map((region) => region.id);
    const communeDestinationIds = freeShipping.communes.map((commune) => commune.id);
    if (regionDestinationIds.includes(sessionStore.currentRegionId) ||
      communeDestinationIds.includes(sessionStore.currentCommuneId)) {
      return `Despacho gratis a tu dirección actual para compras sobre ${minimumPurchaseAmount}.`;
    }

    return '';
  })?.join('<br>');

  const baseSubsidizedText = orderBy(subsidizedShippings.value, 'minimumAmountCents', 'asc').map(
    (subsidizedShipping) => {
      const minimumPurchaseAmount = useFormatCurrency(subsidizedShipping.minimumAmountCents);
      let subsidizedAmount = subsidizedShipping.subsidizedAmountCents;
      if (subsidizedShipping.kind === 'with_lokal') {
        subsidizedAmount *= DOUBLE;
      }

      return `Despachos con descuentos de hasta ${useFormatCurrency(subsidizedAmount)}
      para compras sobre ${minimumPurchaseAmount}.`;
    },
  )?.join('<br>');

  return `${baseText}<br>${baseSubsidizedText}`;
});

// blurhash
const blurhashCanvas = ref<HTMLCanvasElement | null>(null);
const imageContainer = ref<HTMLDivElement | null>(null);
const backgroundImageStyle = ref('');
const showBlurhash = ref(true);
// eslint-disable-next-line max-statements, complexity
function renderBlurhash() {
  if (blurhashCanvas.value && props.maker.image?.imageBlurhash) {
    const ctx = blurhashCanvas.value.getContext('2d');
    const makerBlurhash = props.maker.image.imageBlurhash;

    if (ctx && !!makerBlurhash && imageContainer.value?.getBoundingClientRect()) {
      let { width, height } = imageContainer.value?.getBoundingClientRect();

      // Set default width and height if they're invalid (0 or undefined)
      width = Math.round(width || DEFAULT_IMAGE_LENGTH);
      height = Math.round(height || DEFAULT_IMAGE_LENGTH);

      blurhashCanvas.value.width = width;
      blurhashCanvas.value.height = height;

      // Decode the Blurhash into pixel data
      const pixels = decode(makerBlurhash, width, height);

      // Create image data with the same dimensions
      const imageData = ctx.createImageData(width, height);

      // Ensure the pixel data fits correctly before setting
      if (pixels.length === imageData.data.length) {
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);
      } else {
        console.error('Pixel data size mismatch! Cannot apply Blurhash.');
      }
    }
  }
}

function preloadImage(url: string) {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    showBlurhash.value = false; // Hide the blurhash once the image is loaded
    backgroundImageStyle.value = `url(${url})`;
  };
}

onMounted(() => {
  const imageUrl = props.maker.image?.derivatives?.jpgMd?.url || greenKImage;
  renderBlurhash();
  preloadImage(imageUrl);
});
</script>
