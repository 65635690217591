<template>
  <div class="flex w-full flex-col items-center">
    <form
      class="mb-5 grid w-full grid-cols-1 text-gray-700 md:grid-cols-2 md:gap-x-1"
      @submit="onSubmit"
    >
      <div>
        <p class="text-sm">
          Nombre de la tienda
        </p>
        <form-input
          v-model="name"
          class="mb-1"
          placeholder="Nombre de la tienda"
          label-name="Nombre de la tienda"
          type="text"
          name="name"
          :rules="{ required: true }"
        />
      </div>
      <div>
        <p class="text-sm">
          Giro comercial
        </p>
        <form-input
          v-model="commercialBusiness"
          class="mb-1"
          placeholder="Giro comercial"
          label-name="Giro comercial"
          type="text"
          label="giro comercial"
          name="commercialBusiness"
          :rules="{ required: true }"
        />
      </div>
      <div>
        <p class="text-sm">
          Rut de la empresa
        </p>
        <form-input
          v-model="businessRut"
          class="mb-1"
          placeholder="Rut de la empresa"
          label-name="Rut de la empresa"
          type="text"
          name="businessRut"
          :rules="{ required: true, rut: true, validateRut: [shop?.id] }"
        />
      </div>
      <div>
        <lokal-tooltip
          main-label="Razón social"
          class="text-sm"
          information="Si eres persona natural, ingresa tu nombre completo."
          button-classes="flex items-center space-x-1"
          information-classes="w-60 p-1 md:p-2"
          position-options="left"
        />
        <form-input
          v-model="socialReason"
          class="mb-1"
          placeholder="Razón social"
          label-name="Razón social"
          type="text"
          name="socialReason"
          :rules="{ required: true }"
        />
      </div>
      <div>
        <p class="text-sm">
          Nombre completo de contacto
        </p>
        <form-input
          v-model="contactName"
          class="mb-1"
          placeholder="Nombre completo de contacto"
          type="text"
          name="contactName"
          label-name="Nombre completo de contacto"
          :rules="{ required: true }"
        />
      </div>
      <div>
        <p class="text-sm">
          Email de contacto
        </p>
        <form-input
          v-model="contactEmail"
          class="mb-1"
          placeholder="Correo de contacto"
          label-name="Correo de contacto"
          type="text"
          name="contactEmail"
          :rules="{ required: true, email: true }"
        />
      </div>
      <div>
        <p class="text-sm">
          Número de contacto
        </p>
        <contact-number-input v-model="contactNumber" />
      </div>
      <div>
        <p class="text-sm">
          Página web o redes sociales
        </p>
        <form-input
          v-model="webpageLink"
          class="mb-1"
          placeholder="Sitio web o link a redes sociales"
          label-name="Sitio web o link a redes sociales"
          type="text"
          name="webpageLink"
          :rules="{ required: false }"
        />
      </div>
      <div class="mb-4">
        <p class="text-sm">
          ¿Eres una tienda presencial o exclusivamente online?
        </p>
        <div
          class="flex flex-col"
          :class="{ 'md:0 mb-1': selectedKind?.id === 3, 'mb-4 md:mb-0': selectedKind?.id !== 3 }"
        >
          <lokalSingleSelector
            v-model="selectedKind"
            :options="shopKindOptions"
            key-option="type"
            option-label="name"
            options-label="Tipo de tienda"
            class=""
          />
          <form-input
            v-model="kind"
            class="hidden"
            placeholder="Tipo de tienda"
            type="text"
            name="shops[kind]"
            label-name="Tipo de tienda"
            :rules="{ required: true }"
          />
          <p
            v-if="selectedKind.id === 2"
            class="text-sm"
          >
            Lokal es una plataforma para abastecer tiendas. Si deseas comprar
            para consumo personal debes hacerlo en la página de cada marca.
          </p>
          <ErrorMessage
            name="shops[kind]"
            class="text-sm text-red-500"
          />
        </div>
      </div>
      <div
        v-if="selectedKind?.id === 3"
        class="mb-4 md:mb-0"
      >
        <p class="hidden text-sm md:block">
          Escriba su tipo de tienda
        </p>
        <form-input
          v-model="otherKind"
          placeholder="Otro tipo de tienda"
          type="text"
          name="shops[other_kind]"
          label-name="Otro tipo de tienda"
          :rules="{ required: true }"
        />
      </div>
      <lokal-loading
        v-if="loading"
        :loading="loading"
        class="self-center md:col-span-2"
        size="small"
      />
      <button
        class="rounded-md bg-lk-green p-2 text-white hover:bg-lk-green-deep hover:text-white md:col-span-2"
        type="submit"
      >
        {{ shop?.id ? 'Guardar' : 'Crea Tienda' }}
      </button>
      <slot name="button-action" />
    </form>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { rutClean } from 'rut-helpers';
import { useForm, ErrorMessage } from 'vee-validate';
import FormInput from './shared/form-input.vue';
import shopsApi from '../api/shops';
import useSessionStore from '../stores/user-store';
import LokalLoading from './shared/lokal-loading.vue';
import lokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import LokalTooltip from './shared/lokal-tooltip.vue';
import ContactNumberInput from './shared/contact-number-input.vue';

const props = defineProps<{
  shop?: Shop,
  goBackOption: boolean,
}>();

const emit = defineEmits<{(e: 'edit-shop', value: boolean): void, (e: 'shop-updated'): void}>();

const sessionStore = useSessionStore();

const { handleSubmit } = useForm();
const name = ref(props.shop?.name || sessionStore.user?.shopName || '');
const commercialBusiness = ref(props.shop?.commercialBusiness || '');
const businessRut = ref(props.shop?.businessRut || '');
const socialReason = ref(props.shop?.socialReason || '');
const contactName = ref(props.shop?.contactName || sessionStore.user?.firstName || '');
const contactEmail = ref(props.shop?.contactEmail || sessionStore.user?.email || '');
const contactNumber = ref(props.shop?.contactNumber?.replace(/^56/, '') ||
  sessionStore.user?.contactNumber?.replace(/^56/, '') || '');
const webpageLink = ref(props.shop?.webpageLink || '');
const shopKindOptions = ref([
  { id: 4, name: 'Tienda física y online', type: 'physical_and_online_store' },
  { id: 0, name: 'Solo tienda física', type: 'physical_store' },
  { id: 1, name: 'Solo tienda online', type: 'online_store' },
  { id: 2, name: 'Consumo personal', type: 'personal_consumption' },
  { id: 3, name: 'Otro', type: 'other' },
]);
const selectedKind = ref(
  shopKindOptions.value.find((option) => props.shop?.kind === option.type) || {} as OptionRecord);
const kind = computed(() => selectedKind.value?.name as string);
const otherKind = ref(props.shop?.id ? props.shop?.otherKind : '');
const form = computed(() => ({
  name: name.value,
  commercialBusiness: commercialBusiness.value,
  businessRut: rutClean(businessRut.value),
  socialReason: socialReason.value,
  contactNumber: `56${contactNumber.value}`,
  contactName: contactName.value,
  contactEmail: contactEmail.value,
  webpageLink: webpageLink.value,
  kind: selectedKind.value?.type as string,
  otherKind: otherKind.value,
}));

const loading = ref(false);
const onSubmit = handleSubmit(async () => {
  loading.value = true;
  if (props.shop?.id) {
    await shopsApi.update(props.shop.id, { ...form.value });
  } else {
    await shopsApi.create({ ...form.value });
    await sessionStore.reloadUser();
  }
  loading.value = false;
  emit('edit-shop', false);
  emit('shop-updated');
});

watch(() => props.shop, () => {
  selectedKind.value = shopKindOptions.value.find((option) => props.shop?.kind === option.type) || {} as OptionRecord;
});
</script>
