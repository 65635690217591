<template>
  <div class="mb-10 flex size-full flex-col items-center text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="mb-3 flex w-full items-center px-2 md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Usuarios</span>
      </p>
    </div>
    <div class="w-full px-2 md:px-0">
      <button
        v-if="showWarning"
        class="mb-3 flex w-full flex-col items-center rounded-md bg-red-500 p-3 text-left text-sm text-white sm:flex-row sm:text-base"
        @click="emit('update-private-information')"
      >
        <ExclamationCircleIcon class="mr-2 size-7 shrink-0" />
        <span>
          Debes completar la información de "nombre completo de contacto", "email de contacto" y
          "teléfono de contacto"antes de modificar las notificaciones.
        </span>
        <span class="shrink-0 rounded-md bg-white px-2 py-1 text-red-500 sm:ml-auto sm:py-2">Ir a completar</span>
      </button>
    </div>
    <div class="flex size-full flex-col rounded-md bg-white px-2 py-3 text-sm sm:text-base md:px-4 md:py-5 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <maker-user-list-card
          v-for="user, index in users"
          :key="index"
          :user="user"
          :maker="maker"
          :edit-notification-disabled="showWarning"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import usersApi from 'api/users';
import MakerUserListCard from './maker-user-list-card.vue';

const props = defineProps<{
  maker: Maker
}>();

const { isLoading, data } = useQuery('users', () => usersApi.index(`maker_id=${props.maker.id}`));
const users = computed(() => data.value?.data.users as User[]);

const showWarning = computed(() =>
  !props.maker.contactName || !props.maker.contactEmail || !props.maker.contactNumber);

const emit = defineEmits<{(e: 'update-private-information'): void}>();
</script>
